import Cookies from 'js-cookie'
import { removeJumpSetToken } from '@/views/clientSide/clientHome/use/staticData'

const TokenKey = 'X-TOKEN'

export function getToken () {
  return Cookies.get(TokenKey)
}
export function setOtherToken (token, domain) {
  return Cookies.set(TokenKey, token, { domain })
}
export function setToken (token) {
  return Cookies.set(TokenKey, token)
}
export function removeOtherToken (domain) {
  return Cookies.remove(TokenKey, { domain })
}
export function removeToken () {
  try {
    removeJumpSetToken()
  } catch (error) {
  }
  return Cookies.remove(TokenKey)
}