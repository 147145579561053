import axios from 'axios'
import store from '@/store'
import router from '@/router'
import baseApi from '@/api/base'
import userApi from '@/api/user'
import auditApi from '@/api/audit'
import courseApi from '@/api/course'
import commonApi from '@/api/common'
import { getToken, setToken, removeToken } from '@/utils/auth'
import {
  getPreviewUrl,
  parseTime,
  randomString,
  restReqSuccData
} from '@/utils'
import { ElMessage, ElMessageBox } from 'element-plus'
import { staticTabs, authTabs } from '@/layout/components/messageInfo/use/staticData.js'
const getDefaultState = () => {
  return {
    userInfo: {},
    token: getToken(),
    expireTimes: 30 * 60 * 1000, // token有效期30分钟
    unreadMessageCount: 0,
    unreadMessageList: 0,
    badgeData: {},
    messageTabs: [...staticTabs] // 信息通知的tab列表
  }
}
const state = getDefaultState()

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
    setToken(token)
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_USER_AVATAR: (state, { fileNo, md5 }) => {
    const userInfo = { ...state.userInfo }
    userInfo.avatar = getPreviewUrl(fileNo, md5)
    userInfo.avatarFileNo = fileNo
    userInfo.avatarMd5 = md5
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
    state.userInfo = userInfo
  },
  SET_BADGE_DATA: (state, data) => {
    state.badgeData = Object.assign(state.badgeData, data)
  },
  SET_UNREAD_MESSAGE_COUNT: (state, val) => {
    state.unreadMessageCount = val || 0
  },
  SET_MESSAGE_TABS: (state, data = []) => {
    state.messageTabs = data.length ? [...staticTabs, ...data] : staticTabs
  }
}

const actions = {
  login ({ commit, state }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      commonApi
        .login({ username: username.trim(), password })
        .then((response) => {
          const { data } = response
          const expiredTime =
            Math.round(new Date().getTime()) + state.expireTimes
          commit('SET_TOKEN', data.token)
          // 存储token和失效时间
          localStorage.setItem('isLogin', true) // 是否登录状态
          localStorage.setItem('expired_time', expiredTime) // 过期时间
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // user logout
  logout ({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      baseApi
        .loginOut()
        .then(() => {
          commit('SET_TOKEN', '')
          localStorage.removeItem('kbmsMenu')
          removeToken() // must remove  token  first
          router.push({ path: '/login' })
          localStorage.clear()
          state.unreadMessageList = []
          state.unreadMessageCount = 0
          state.badgeData = {}
          store.dispatch('app/openPrivacyPolicyDialog', false)
          // reset visited views and cached views
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 重置token信息
  resetToken ({ commit }) {
    return new Promise((resolve) => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      localStorage.clear()
      resolve()
    })
  },
  // 公共上传附件方法
  uploadFile ({ state }, { uri, params }) {
    return new Promise((resolve, reject) => {
      params.append('fromSystem', 'web_H5')
      params.append('toSystem', 'mng_base_server')
      params.append('reqId', randomString())
      if (!params.get('reqTime')) {
        params.append('reqTime', parseTime(new Date().getTime()))
      }
      const token = getToken()
      axios({
        // 用axios发送post请求
        method: 'post',
        url: uri, // 请求地址
        data: params, // 参数
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: token,
          fileUid: params.get('file') && params.get('file').fileUid
        }
      })
        .then((res) => {
          if (res.data.code === 0) {
            resolve(res.data)
          } else {
            // token验证失败，失效
            if (res.data.code === 100004 || res.data.code === 100005) {
              // to re-login
              return ElMessageBox.confirm('请重新登录', '提示', {
                confirmButtonText: '去登录',
                cancelButtonText: '取消',
                type: 'warning'
              })
                .then(() => {
                  store.dispatch('user/resetToken').then(() => {
                    location.reload()
                  })
                })
                .catch(() => { })
            } else {
              ElMessage({
                message: res.data.msg || 'Error',
                type: 'error',
                showClose: true
              })
              reject(res.data.msg || 'Error') // 'error'
            }
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getUserInfo ({ commit, state }) {
    const localUserInfo = localStorage.getItem('userInfo')
      ? JSON.parse(localStorage.getItem('userInfo'))
      : {}
    const stateUserIsEmpty = Object.values(state.userInfo).length === 0
    if (stateUserIsEmpty) {
      commit('SET_USERINFO', localUserInfo)
    }
    return stateUserIsEmpty ? localUserInfo : state.userInfo
  },
  // 获取待审批文章数量
  getArticleAuditCountData: (
    { commit },
    data
  ) => {
    const { callback, ...otherParams } = data || {}
    const { pageNum = 1, pageSize = 10, needAudit = true, statusList = 'pending' } = otherParams || {}
    const statusListData = typeof statusList === 'string' ? [statusList] : statusList
    restReqSuccData(
      auditApi.articleList,
      {
        ...otherParams,
        pageNum,
        pageSize,
        needAudit,
        statusList: statusListData
      },
      (res) => {
        if (statusListData.includes('pending')) {
          commit('SET_BADGE_DATA', {
            articleAudit: {
              count: res?.totalCount || 0,
              list: res?.list || [],
              parentCode: 'infoRelease'
            }
          })
        }
        if (callback) {
          callback(res)
        }
      }
    )
  },
  // 获取未读信息
  getUnreadMsg: ({ state }, params = {}) => {
    restReqSuccData(
      userApi.notificationList,
      {
        ...params,
        pageSize: 1000,
        pageNum: 1,
        isRead: 0
      },
      (res) => {
        state.unreadMessageList = res?.list
        state.unreadMessageCount = res?.totalCount || 0
      }
    )
  },
  // 已读消息
  readMsg: ({ dispatch }, data) => {
    const { id = '', listParams = {}, callback = null } = data || {}
    const reqMe = userApi[!id ? 'readAllMsg' : 'readMsg']
    restReqSuccData(reqMe, !id ? { requestMethod: 'get' } : { id }, (res) => {
      if (res) {
        if (!id) {
          ElMessage.success('全部已读成功')
        }
        if (callback) {
          callback()
        }
        dispatch('getUnreadMsg', listParams)
      }
    })
  },
  // 获取待审批课程数量
  getCourseAuditCountData: (
    { commit },
    data
  ) => {
    const { callback, ...otherParams } = data || {}
    const { pageNum = 1, pageSize = 10, needAudit = true, statusList = 'pending' } = otherParams || {}
    const statusListData = typeof statusList === 'string' ? [statusList] : statusList
    restReqSuccData(
      courseApi.auditList,
      {
        ...otherParams,
        pageNum,
        pageSize,
        needAudit,
        statusList: statusListData
      },
      (res) => {
        if (statusListData.includes('pending')) {
          commit('SET_BADGE_DATA', {
            courseAudit: {
              count: res?.totalCount || 0,
              list: res?.list || [],
              parentCode: 'trainCenter'
            }
          })
        }
        if (callback) {
          callback(res)
        }
      }
    )
  },
  getUserMessageTabs: ({ commit }, menuData) => {
    const authList = []
    const loopMenu = (list = menuData) => {
      for (const i in list) {
        if (list[i].subResource?.length) {
          loopMenu(list[i].subResource)
        } else {
          if (authTabs[list[i].resourceCode]) {
            authList.push(authTabs[list[i].resourceCode])
          }
        }
      }
    }
    loopMenu()
    commit('SET_MESSAGE_TABS', authList)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
