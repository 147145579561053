
import request from '@/api'

const userApi = {}
const urls = [
  { api: 'userInfo', path: 'session/current/user' }, // 获取用户信息
  { api: 'avatarUpload', path: 'avatar/upload' }, // 修改资料-上传头像
  { api: 'notificationList', path: 'notification/list' }, // 消息中心
  { api: 'readMsg', path: 'notification/read' }, // 消息中心-读通知
  { api: 'readAllMsg', path: 'notification/read/all' } // 消息中心-全读
]

urls.forEach((url) => {
  userApi[url.api || url] = (data, method) => {
    const config = {
      url: `/kbms/${url.path || url}`,
      method: method || 'post',
      data
    }
    return request(config)
  }
})
export default userApi