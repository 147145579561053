<template>
  <el-tabs v-model="activeName" class="center-tabs" @tab-change="changeTab">
    <el-tab-pane
      v-for="item in tabList"
      :key="item.name"
      :label="item.label"
      :name="item.name"
    >
      <template #label>
        <el-badge :value="numsData[item.name] || 0" :max="99" :hidden="!props.showNum || !numsData[item.name]" class="item">
          {{ item.label }}
        </el-badge>
      </template>
    </el-tab-pane>
  </el-tabs>
</template>

<script setup>
import { computed, ref, watchEffect } from 'vue'
const props = defineProps({
  active: String,
  tabList: {
    type: Array,
    default: () => []
  },
  showNum: {
    type: Boolean,
    default: false
  },
  numList: {
    type: Object,
    default: () => {}
  }
})
const activeName = ref('')
const numsData = computed(() => props.numList || {})
watchEffect(() => {
  activeName.value = props.active
})
const emits = defineEmits(['change'])
const changeTab = (name) => {
  emits('change', name)
}
</script>

<style lang="scss" scoped>
.center-tabs {
  width: 100%;
  --el-color-danger: #eb5757;
  :deep(.el-tabs__nav) {
    margin-left: 16px;
  }
  :deep(.el-tabs__item) {
    width: 76px;
    height: 64px;
    color: #585e67;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }
}
</style>
