export default function watermark (settings) {
  // 默认设置
  const defaultSettings = {
    watermark_txt: settings?.watermark_txt || '',
    watermark_x: settings.x || 10, // 水印起始位置x轴坐标
    watermark_y: settings.y || 10, // 水印起始位置Y轴坐标
    watermark_rows: settings.rows || 20, // 水印行数
    watermark_cols: settings.cols || 20, // 水印列数
    watermark_x_space: settings.xSpace || 50, // 水印x轴间隔
    watermark_y_space: settings.ySpace || 50, // 水印y轴间隔
    watermark_color: settings.color || '#ddd', // 水印字体颜色
    watermark_alpha: settings.alpha || 0.3, // 水印透明度
    watermark_fontsize: settings.fontSize || '12px', // 水印字体大小
    watermark_fontWeight: settings.fontWeight || 400, // 水印字体大小
    watermark_font: '微软雅黑', // 水印字体
    watermark_width: settings.width || 200, // 水印宽度
    watermark_height: settings.height || 40, // 水印长度
    watermark_angle: settings.angle || 30, // 水印倾斜度数
    watermark_zindex: settings?.watermark_zindex || 1, // 水印zindex
    className: settings?.className || '' // 水印区域
  }
  // 采用配置项替换默认值
  if (arguments.length === 1 && typeof arguments[0] === 'object') {
    const src = arguments[0] || {}
    for (const key in src) {
      if (
        src[key] &&
        defaultSettings[key] &&
        src[key] === defaultSettings[key]
      ) {
        continue
      } else if (src[key]) defaultSettings[key] = src[key]
    }
  }

  const oTemp = document.createDocumentFragment()

  // 获取页面最大宽度
  const pageWidth = settings.pageWidth ||
    Math.max(document.body.scrollWidth, document.body.clientWidth) - 200
  // 获取页面最大长度
  const pageHeight = settings.pageHeight ||
    Math.max(document.body.scrollHeight, document.body.clientHeight) - 100

  // 如果将水印列数设置为0，或水印列数设置过大，超过页面最大宽度，则重新计算水印列数和水印x轴间隔
  if (
    defaultSettings.watermark_cols === 0 ||
    parseInt(
      defaultSettings.watermark_x +
      defaultSettings.watermark_width * defaultSettings.watermark_cols +
      defaultSettings.watermark_x_space * (defaultSettings.watermark_cols - 1)
    ) > pageWidth
  ) {
    defaultSettings.watermark_cols = parseInt(
      (pageWidth -
        defaultSettings.watermark_x +
        defaultSettings.watermark_x_space) /
      (defaultSettings.watermark_width + defaultSettings.watermark_x_space)
    )
    defaultSettings.watermark_x_space = parseInt(
      (pageWidth -
        defaultSettings.watermark_x -
        defaultSettings.watermark_width * defaultSettings.watermark_cols) /
      (defaultSettings.watermark_cols - 1)
    )
  }
  // 如果将水印行数设置为0，或水印行数设置过大，超过页面最大长度，则重新计算水印行数和水印y轴间隔
  if (
    defaultSettings.watermark_rows === 0 ||
    parseInt(
      defaultSettings.watermark_y +
      defaultSettings.watermark_height * defaultSettings.watermark_rows +
      defaultSettings.watermark_y_space * (defaultSettings.watermark_rows - 1)
    ) > pageHeight
  ) {
    defaultSettings.watermark_rows = parseInt(
      (defaultSettings.watermark_y_space +
        pageHeight -
        defaultSettings.watermark_y) /
      (defaultSettings.watermark_height + defaultSettings.watermark_y_space)
    )
    defaultSettings.watermark_y_space = parseInt(
      (pageHeight -
        defaultSettings.watermark_y -
        defaultSettings.watermark_height * defaultSettings.watermark_rows) /
      (defaultSettings.watermark_rows - 1)
    )
  }
  let x
  let y
  for (let i = 0; i < defaultSettings.watermark_rows; i++) {
    y =
      defaultSettings.watermark_y +
      (defaultSettings.watermark_y_space + defaultSettings.watermark_height) * i
    for (let j = 0; j < defaultSettings.watermark_cols; j++) {
      x =
        defaultSettings.watermark_x +
        (defaultSettings.watermark_width + defaultSettings.watermark_x_space) *
        j

      const maskDiv = document.createElement('div')
      maskDiv.id = 'maskDiv' + i + j
      maskDiv.appendChild(
        document.createTextNode(defaultSettings.watermark_txt)
      )
      // 设置水印div倾斜显示
      maskDiv.style.userSelect = 'none'
      maskDiv.style.pointerEvents = 'none'
      maskDiv.style.webkitTransform =
        'rotate(-' + defaultSettings.watermark_angle + 'deg)'
      maskDiv.style.MozTransform =
        'rotate(-' + defaultSettings.watermark_angle + 'deg)'
      maskDiv.style.msTransform =
        'rotate(-' + defaultSettings.watermark_angle + 'deg)'
      maskDiv.style.OTransform =
        'rotate(-' + defaultSettings.watermark_angle + 'deg)'
      maskDiv.style.transform =
        'rotate(-' + defaultSettings.watermark_angle + 'deg)'
      maskDiv.style.visibility = ''
      maskDiv.style.position = 'absolute'
      maskDiv.style.left = x + 'px'
      maskDiv.style.top = y + 'px'
      maskDiv.style.overflow = 'hidden'
      //  maskDiv.style.zIndex = '9999';
      maskDiv.style.opacity = defaultSettings.watermark_alpha
      maskDiv.style.fontSize = defaultSettings.watermark_fontsize
      maskDiv.style.fontFamily = defaultSettings.watermark_font
      maskDiv.style.fontWeight = defaultSettings.watermark_fontWeight
      maskDiv.style.color = defaultSettings.watermark_color
      maskDiv.style.zIndex = defaultSettings.watermark_zindex
      maskDiv.style.textAlign = 'center'
      maskDiv.style.width = defaultSettings.watermark_width + 'px'
      maskDiv.style.height = defaultSettings.watermark_height + 'px'
      maskDiv.style.display = 'block'
      oTemp.appendChild(maskDiv)
    }
  }
  if (defaultSettings.className) {
    document.querySelector('.' + defaultSettings.className).appendChild(oTemp)
  } else {
    document.body.appendChild(oTemp)
  }
}
