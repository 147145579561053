<template>
  <div
    class="emptyBox flex"
    :style="{ width: imgSize ? imgSize + 'px' : '100%' }"
  >
    <img
      class="emptyImg"
      :src="
        imgMap[props.emptyName].img
          ? require(`@/assets/images/empty/${imgMap[props.emptyName].img}`)
          : props.src
      "
      alt=""
    />
    <div class="emptyTxt">
      <slot name="empty-text">{{storeEmptyText || text || imgMap[props.emptyName].text }}</slot>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
const imgMap = {
  404: {
    img: '404.svg',
    text: '找不到页面了 \n 刷新试试～'
  },
  search: {
    img: 'search.svg',
    text: '搜索不到结果 \n 换个词试试吧～'
  },
  notice: {
    img: 'notice.svg',
    text: '还没有消息呢～'
  },
  err: {
    img: 'err.svg',
    text: '系统出错了 \n 稍后再试一下～'
  },
  empty: {
    img: 'empty.svg',
    text: '此目录下暂没有更新文档 \n 下次再来看看吧～'
  },
  emptyContent: {
    img: 'empty.svg',
    text: '暂无内容'
  },
  comment: {
    img: 'comment.svg',
    text: '没有人评论 \n 快来抢沙发吧～'
  },
  collect: {
    img: 'collect.svg',
    text: '没有收藏的内容 \n 快去逛逛吧～'
  },
  errImg: {
    img: 'errImg.svg',
    text: ''
  },
  errTips: {
    img: 'errTips.svg',
    text: '提示 \n 您已经提交了试卷'
  }
}
const props = defineProps({
  src: String,
  emptyName: {
    type: String,
    default: 'emptyContent'
  },
  imgSize: {
    type: Number,
    default: 0
  },
  text: {
    type: String,
    default: ''
  }
})
const store = useStore()
const { reqData } = store.state.app
const storeEmptyText = computed(
  () => reqData.responseStatus !== 200 && reqData.emptyText
)
</script>

<style lang="scss" scoped>
.emptyBox {
  max-width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: var(--empty-margin,0 auto);
  .emptyImg {
    display: block;
    flex: 1;
    overflow: hidden;
    width: 100%;
  }
  .emptyTxt {
    color: var(--empty-color, #585e67);
    font-size: 12px;
    line-height: 22px;
    padding-top: 8px;
    white-space: break-spaces;
    text-align: center;
    &:empty {
      display: none;
    }
  }
}
</style>
