<template>
  <div
    class="menu-box"
    v-loading="loading"
  >
    <div wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeIndex"
        mode="vertical"
        :default-openeds="defaultOpeneds"
      >
        <div
          v-for="(item, index) in allMenu"
          :key="index"
        >
          <SidebarItem
            :subMenu="item"
            :activeIndex="activeIndex"
            @setActiveCode="getActiveCode"
            :allMenu="allMenu"
          />
        </div>
      </el-menu>
    </div>
  </div>
</template>

<script>
import SidebarItem from './item.vue'
import { formatLower } from '@/utils'
import store from '@/store'

export default {
  name: 'sidebar',
  components: { SidebarItem },
  data () {
    return {
      loading: false,
      allMenu: [],
      activeIndex: 'articleRelease',
      defaultOpeneds: []
    }
  },
  created () {
    this.getAllMenu()
    this.activeIndex = formatLower(this.$route.name)
    this.defaultOpeneds = []
    this.defaultOpeneds.push(this.activeIndex)
    this.getActiveCode(this.activeIndex)
  },
  watch: {
    $route (route) {
      this.activeIndex = formatLower(route.name)
      if (this.activeIndex === 'createDocument') {
        this.resetIcon(this.allMenu)
      }
    }
  },
  methods: {
    // 获取菜单信息
    getAllMenu () {
      const menu = JSON.parse(localStorage.getItem('kbmsMenu')) || store.state.app.kbmsMenu
      if (menu?.subResource?.length) {
        this.allMenu = menu.subResource.map(item => {
          return {
            ...item,
            iconSrc: item.resourceCode === this.activeIndex ? `${item.resourceCode}_active` : item.resourceCode
          }
        })
      } else {
        this.allMenu = []
      }
      this.setParentCode(this.allMenu)
    },
    // 设置菜单父节点来高亮菜单图标
    setParentCode (data) {
      if (data.length) {
        data.forEach(item => {
          if (item.subResource.length) {
            item.subResource.forEach(s => {
              s.parentCode = item.resourceCode
              this.setParentCode(s.subResource)
            })
          }
        })
      }
    },
    // 重置菜单图标
    resetIcon (data) {
      if (data.length) {
        for (let i = 0; i < data.length; i++) {
          const item = data[i]
          if (item?.subResource?.length) {
            const subResource = item.subResource
            for (let j = 0; j < subResource.length; j++) {
              const subItem = subResource[j]
              item.iconSrc = subItem.parentCode
            }
          } else {
            item.iconSrc = item.resourceCode
          }
        }
      }
    },
    // 设置当前激活的状态
    getActiveCode (code) {
      for (let i = 0; i < this.allMenu.length; i++) {
        const item = this.allMenu[i]
        item.iconSrc = item.resourceCode
        if (item.resourceCode === code) {
          item.iconSrc = item.resourceCode + '_active'
        } else {
          item.iconSrc = item.resourceCode
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.menu-box {
  margin: 16px 8px;
  .el-menu {
    background: none !important;
    border-right: none;
    .el-menu-item:hover {
      border-radius: 4px;
      background: #f2f3f5;
    }
    .hide-icon {
      :deep(.el-sub-menu__icon-arrow) {
        display: none;
      }
    }
  }
}
</style>