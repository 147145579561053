import axios from '@/utils/request'

// 接口封装
function request (data) {
  return new Promise((resolve, reject) => {
    axios(data).then(res => {
      resolve(res)
    }).catch(error => {
      if (error.message && error.message === 'Network Error') {
        axios(data).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      } else {
        reject(error)
      }
    })
  })
}

export default request