import { ElMessage, ElLoading, ElMessageBox } from 'element-plus'

const $loading = (message, options = {}) => {
  return ElLoading.service({ text: message, lock: true, background: 'rgba(0, 0, 0, 0.5)', ...options })
}

const $tips = (message, options = {}) => {
  return ElMessage({ message, showClose: true, ...options })
}
const $success = (message, options = {}) => {
  return ElMessage({ message, type: 'success', showClose: true, ...options })
}
const $warn = (message, options = {}) => {
  return ElMessage({ message, type: 'warning', showClose: true, ...options })
}
const $error = (message, options = {}) => {
  return ElMessage({ message, type: 'error', showClose: true, ...options })
}
const $alert = (message, options = {}) => {
  options = { title: '', btn: '确定', confirm: () => {}, ...options }
  return ElMessageBox.alert(message, options.title, {
    confirmButtonText: options.btn,
    callback: () => {
      options.confirm()
    }
  })
}
const $confirm = (message, options = {}) => {
  options = { title: '', btn: '确定', btn_cancle: '取消', confirm: () => {}, cancle: () => {}, type: 'warning', ...options }
  return ElMessageBox.confirm(message, {
    title: options.title || '提示',
    confirmButtonText: options.btn,
    cancelButtonText: options.btn_cancle,
    type: options.type,
    closeOnPressEscape: false, // 按下 ESC 键关闭弹窗
    closeOnClickModal: false, // 点击遮罩关闭弹窗
    distinguishCancelAndClose: true,
    dangerouslyUseHTMLString: options.isHtml || false // 是否使用html标签
  })
    .then(() => {
      options.confirm()
    })
    .catch(() => {
      options.cancle()
    })
}
export default {
  install (app) {
    app.config.globalProperties.$loading = $loading
    app.config.globalProperties.$tips = $tips
    app.config.globalProperties.$success = $success
    app.config.globalProperties.$warn = $warn
    app.config.globalProperties.$error = $error
    app.config.globalProperties.$alert = $alert
    app.config.globalProperties.$confirm = $confirm
    // 可以进行全局 app.provide  app.directive app.mixin
  }
}