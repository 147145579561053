const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  kbmsMenu: state => state.app.kbmsMenu,
  userInfo: state => {
    const localUserInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {}
    const stateUserIsEmpty = JSON.stringify(state.user.userInfo) === '{}'
    return stateUserIsEmpty ? localUserInfo : state.user.userInfo
  },
  userId: state => {
    const localUserInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {}
    const stateUserIsEmpty = JSON.stringify(state.user.userInfo) === '{}'
    return stateUserIsEmpty ? localUserInfo.sysUserId : state.user.userInfo.sysUserId
  },
  visitedViews: state => state.tagsView.visitedViews,
  curTagTitle: state => state.tagsView.curTagTitle
}
export default getters