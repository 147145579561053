const examCenterRoutes = [{
  path: 'examCenter',
  name: 'ExamCenter',
  redirect: '/examCenter/questionStoreManagement',
  meta: { title: '考试中心' },
  children: [
    {
      path: 'questionStoreManagement',
      name: 'QuestionStoreManagement',
      component: () => import('@/views/examCenter/questionStoreManagement/index'),
      meta: { title: '题库管理', keepAlive: true, isMenu: true }
    },
    {
      path: 'examQuestionsStore',
      name: 'ExamQuestionsStore',
      component: () => import('@/views/examCenter/examQuestionsStore/index'),
      meta: { title: '试题库', keepAlive: true, isMenu: true }
    },
    {
      path: 'addExamQuestion/:id?/:title?',
      name: 'AddExamQuestion',
      component: () => import('@/views/examCenter/examQuestionsStore/addExamQuestion/index'),
      meta: { title: '新增试题库', keepAlive: true, isMenu: true, resetTagTitle: true, tagTitleSuffix: '题库' }
    },
    {
      path: 'examTask',
      name: 'ExamTask',
      component: () => import('@/views/examCenter/examTask/index'),
      meta: { title: '考试任务', keepAlive: true, isMenu: true }
    },
    {
      path: 'addExamTask/:id?/:title?',
      name: 'AddExamTask',
      component: () => import('@/views/examCenter/examTask/addExamTask/index'),
      meta: { title: '新增考试任务', isMenu: true, resetTagTitle: true, tagTitleSuffix: '考试任务' }
    },
    {
      path: 'examResult/:id/:title',
      name: 'ExamResult',
      component: () => import('@/views/examCenter/examTask/examResult/index'),
      meta: { title: '考试结果', isMenu: true, resetTagTitle: true, tagTitleSuffix: '考试任务' }
    },
    {
      path: 'examScore',
      name: 'ExamScore',
      component: () => import('@/views/examCenter/examScore/index'),
      meta: { title: '考试评分', keepAlive: true, isMenu: true }
    },
    {
      path: 'examScorePage/:id/:title?',
      name: 'ExamScorePage',
      component: () => import('@/views/examCenter/examScore/scorePage'),
      meta: { title: '考试评分', keepAlive: true, isMenu: true, resetTagTitle: true, tagTitleSuffix: '考试评分' }
    },
    {
      path: 'practiceTest',
      name: 'PracticeTest',
      component: () => import('@/views/examCenter/practiceTest/index'),
      meta: { title: '模拟任务', keepAlive: true, isMenu: true }
    },
    {
      path: 'addPracticeTest/:id?/:title?',
      name: 'AddPracticeTest',
      component: () => import('@/views/examCenter/practiceTest/addPracticeTest/index'),
      meta: { title: '新增模拟任务', isMenu: true, resetTagTitle: true, tagTitleSuffix: '模拟任务' }
    },
    {
      path: 'practiceTestResult/:id/:title',
      name: 'PracticeTestResult',
      component: () => import('@/views/examCenter/practiceTest/practiceTestResult/index'),
      meta: { title: '模拟结果', isMenu: true, resetTagTitle: true, tagTitleSuffix: '模拟任务' }
    }
  ]
}]
export default examCenterRoutes