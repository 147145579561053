const infoData = [{
  title: '第一章 总则',
  desc: `<p>第一条 本规定旨在建立一个公司信息发布平台，以促进公司内部（包括长帆物流的下属分、子公司）的信息交流、经验共享和学习。该平台主要用于发布公司的企业文化宣传、通知、公告、新闻、规章制度、案例分享和行业知识等信息。为了规范该平台的使用秩序，明确使用者和浏览者的权利义务与责任，特制定本规定。</p>
  <p>第二条 本规定适用于长帆信息发布平台，网址为 <a href="https://eim.longsailing.net/">https://eim.longsailing.net/</a>。</p>
  <p>第三条 员工首次登录信息发布平台，需学习信息平台管理规定，以此确保员工了解和遵守本规定的要求。</p>`
}, {
  title: '第二章 用户的权利、义务与责任',
  desc: `<p>第四条 用户昵称注册依照实名的原则。</p>
  <p>第五条 员工手机号作为系统登录账号，账号由流程与信息技术中心IT支持部生成，个人无需注册申请。员工使用手机号登录系统账号后，即可成为信息发布平台的使用者（以下称为“用户”）。新员工在入职时，默认授权公司通过手机号自动注册系统账号。若员工离职，其系统账号将自动注销。<span class='weight'>用户在同意本规定的全部条款后，方能享有平台各项权利，并相应履行平台义务以及承担平台责任。首次使用员工账号登录平台后，即视为用户同意本规定的全部条款。<span></p>
  <p>第六条 本信息发布平台倡导实话实说，用户在平台有自由发言的权利，因受到违反本规定而被关闭权限的除外。<span class='weight'>用户发言必须以事实为依据，实事求是。</span></p>
  <p>第七条 用户有匿名发言的权利。</p>
  <p>第八条 <span class='weight'>用户账号和密码由用户负责保管；用户不应将其账号、密码出借给他人使用；如用户转借账号，所有责任和后果由用户承担，同时本平台有权收回或封禁该账号。</span></p>
  <p>第九条 用户应当对以其账号进行的所有活动和事件负法律责任；用户应遵守中华人民共和国法律法规和其他一切适用的法律法规和规定，自行承担一切可能因平台活动直接或间接引起的法律责任。</p>
  <p>第十条 用户在使用平台服务过程中，必须遵循以下原则：</p>
  <p>1. 遵守所有适用的法律法规和社会公德；</p>
  <p>2. 遵守所有与网络服务有关的网络协议、规定和程序；</p>
  <p>3. 遵守本管理规定，确保合法性、合理性和安全性；</p>
  <p>4. 不得利用平台进行盗窃、诈骗、敲诈勒索；</p>
  <p>5. 不得侵犯其他任何第三方的专利权、著作权、商标权、商业秘密、名誉权或其他任何合法权益；</p>
  <p>6. 禁止发表与平台宗旨无关主题，不得讨论任何有关经济、军事、外交等社会公共事务的报道、评论，以及有关社会突发事件的报道、评论；</p>
  <p>7. 严禁破坏平台公共秩序及侵害平台利益的其他行为，包括但不限于故意制作、传播计算机病毒等破坏性程序，攻击计算机系统及通信网络等行为；</p>
  <p>8. 严禁进行任何形式的炒作话题、造谣行为，不得散布虚假、骚扰、中伤、谩骂、恐吓、淫秽、色情、赌博、暴力、凶杀、血腥、恐怖、人身攻击或教唆犯罪等非法或不当的内容；</p>
  <p>9. 不得有侮辱或者诽谤他人，侵害他人合法权益的行为；</p>
  <p>10. 不得违反公司信息安全或网络安全相关管理规定，包括但不限于个人薪酬待遇保密原则等相关规章制度；</p>
  <p>11. 不得传教、集会或倡议集体争取公众权益等行为；</p>
  <p>12. 不得破坏国家宗教政策，宣扬邪教和封建迷信；</p>
  <p>13. 不得张贴未经公开报道或未经证实的消息，转载请注明出处，亲身经历请标明具体时间、地点等；</p>
  <p>14. 不得散布其他违反国家法律法规、扰乱平台言论秩序的内容；</p>
  <p>15. 不得以任何形式借贷款、集资、广告，禁止一切涉及商业利益或个人谋利。</p>
  <p>第十一条 <span class='weight'>如用户在使用平台服务时违反任何上述规定，平台有权要求用户改正或直接采取一切必要的措施（包括但不限于：更改或删除用户张贴的内容等、暂停或终止用户发表言论的权利；违反公司管理规定或者国家法律法规的，平台保留进一步要求用户承担相应责任的权利），平台视情节轻重有权对其进行警告、线上约谈等处理，直至交由相关部门追究责任，以减轻用户不当行为造成的影响。</span></p>
  <p>第十二条 用户有权向信息发布平台管理部门举报违规发布信息行为，若发现信息安全问题或漏洞，应立即报告平台管理部门人力资源中心，确保问题得到及时解决。</p>  
  `
}, {
  title: '第三章 管理员的义务和职责',
  desc: `<p>第十三条 本信息发布平台管理员由相关部门人员担任，相关栏目和板块的管理员负责信息的收集、审核、发布工作，确保信息的真实性、准确性和及时性。</p>
  <p>第十四条 管理员应遵守公司的账户安全管理规定，妥善保管自己的账户和密码，避免泄漏给无关人员，不得将账户用于非授权用途。为了确保信息存储和传输的安全性，管理员应采取必要的安全措施，包括但不限于使用强密码、定期更换密码、使用加密技术等。</p>
  <p>第十五条 管理员在遇到任何技术问题或疑问时，应及时向流程与信息技术中心报告，以确保问题得到及时解决。</p>`
}, {
  title: '第四章 用户隐私保护和保密规定',
  desc: `<p>第十六条 保护用户隐私是信息发布平台的一项基本政策，信息发布平台不向第三方提供单个用户的注册资料，但下列情况除外：</p>
  <p>1. 根据有关的法律法规要求；</p>
  <p>2. 用户严重违反公司管理规定。</p>
  <p>第十七条 用户应严格遵守公司规定的保密制度，保护公司的商业机密和知识产权，维护公司的业务安全和稳定：</p>
  <p>1. <span class='weight'>对于平台内部的资料，包括培训资料、规章制度等，不得随意发布或对外公开；</span></p>
  <p>2. <span class='weight'>用户不得将平台内部资料以任何形式，包括但不限于复制、截图、拍照、传输等方式，泄露给外部人员，包括公司内部无关人员。</span></p>
  <p>3. <span class='weight'>用户不得在私人社交媒体、电子邮件、网络论坛等公共场合谈论平台内部的资料，更不得对外公开；</span></p>
  <p>4. <span class='weight'>用户如违反上述规定的行为，将视情节轻重，依法依规进行处理，涉及严重违规行为的，公司将解除劳动合同并追究法律责任。</span></p>`
}, {
  title: '第五章 版权申明',
  desc: `<p>第十八条 用户转帖应注明原始出处和时间，并承担由此可能产生的版权法律责任。</p>
  <p>第十九条 信息发布平台根据版面管理需要有权删除转帖。</p>
  <p>第二十条 用户同意公司享有用户在信息发布平台发布的原创内容（包括作品中的用户个人肖像）免费的、永久的、全球范围内、在公司任何商业活动中（包括但不限于设计、印刷、销售、宣传、任何展示公司或公司产品或服务的活动）无限制地复制、修改、发行、出租、信息网络传播分发被授权作品及其演绎作品的权利等著作权。</p>`
}, {
  title: '第六章 附则',
  desc: `
  <p>第二十一条 信息发布平台用户观点只代表发布用户本身，不代表信息发布平台以及公司立场，发帖者责任自负。</p>
  <p>第二十二条 信息发布平台不对用户发布内容的真实性、合法性、准确性负责，浏览者对信息发布平台发布内容应理性判断，如认为发布内容不当或给其造成损害，可通知信息发布平台，信息发布平台视情况确定是否采取删除、屏蔽、断开链接等措施。</p>
  <p>第二十三条 信息发布平台有权根据平台发展需要对本规定进行修订。人力资源中心享有本规定的最终解释权。</p>
  <p>第二十四条 本规定自发布之日起生效。</p>
  `
}]
export default infoData