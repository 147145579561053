<template>
  <div class="top-nav">
    <div class="logo-box">
      <img src="@/assets/images/common/logo.png" alt="长帆知识库" />
    </div>
    <div class="tag-box">
      <Tags />
    </div>
    <div class="btns-box">
      <NavBtn />
    </div>
  </div>
</template>

<script>
import Tags from './tags'
import NavBtn from './navbtn'
export default {
  name: 'topNav',
  components: {
    Tags,
    NavBtn
  },
  data () {
    return {
    }
  },
  created () { },
  mounted () { },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.top-nav {
  display: flex;
  height: 64px;
  border-bottom: 1px solid #E5E6EB;
  background: #FFF;
  .logo-box {
    display: flex;
    align-items: center;
    width: 240px;
    justify-content: flex-start;
    flex-shrink: 0;
    img {
      margin-left: 24px;
    }
  }
  .tag-box {
    flex: 1;
    margin-right: 15px;
    overflow: hidden;
  }
  .btns-box {
    margin-right: 15px;
    display: flex;
    align-items: center;
  }
}
</style>