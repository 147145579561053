import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/layout'
import ClientViewLayout from '@/layout/clientViewIndex'
import Login from '@/views/login'
import infoManagement from './modules/infoManagement'
import clientChildren from './modules/client'
import personalCenter from './modules/personalCenter'
import trainCenterRoutes from './modules/trainCenter'
import examCenterRoutes from './modules/examCenter'
import honorSystemRouter from './modules/honorSystem'

// vue项目自带路由
const routes = [
  {
    path: '/',
    component: Layout,
    redirect: 'client/clientHome',
    children: [
      ...infoManagement,
      ...trainCenterRoutes,
      ...examCenterRoutes,
      ...honorSystemRouter
    ]
  },
  {
    path: '/login',
    name: 'Login',
    meta: { title: '登录' },
    component: Login
  },
  {
    path: '/client',
    component: ClientViewLayout,
    redirect: '/client/clientHome',
    children: [...clientChildren, ...personalCenter]
  },
  {
    path: '/404',
    name: 'notFound',
    component: () => import('@/views/notFound'),
    hidden: true
  },
  {
    path: '/privacyPolicy',
    name: 'PrivacyPolicy',
    meta: { title: '管理规定' },
    component: () => import('@/views/privacyPolicy/index')
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404',
    hidden: true
  }
]

const routers = [...routes]

const router = createRouter({
  history: createWebHashHistory(),
  routes: routers
})

export default router