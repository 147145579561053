<template>
  <el-breadcrumb class="fixWidth" separator="/" v-if="breadcrumbShow && breadcrumbData.length>0">
    <template v-for="(item, index) in breadcrumbData" :key="item.title">
      <el-breadcrumb-item
        replace
        :class="{ isLink: item.pathName }"
        @click="jumpHandle(item, index)"
      >
        {{ item.name || item.pageName }}
      </el-breadcrumb-item>
    </template>
  </el-breadcrumb>
</template>

<script setup>
import { routerJumpChange } from '@/utils'
import { computed, watchEffect } from 'vue'
import { useStore } from 'vuex'
const props = defineProps({
  crumbs: {
    type: Array,
    default: () => []
  }
})
const store = useStore()
const breadcrumbData = computed(() => props.crumbs.length ? props.crumbs : store.state.app.breadcrumbData)
const breadcrumbShow = computed(
  () => store.state.app.clientPageConfig.breadcrumb
)
watchEffect(() => {
  if (!breadcrumbShow.value) {
    store.dispatch('app/setBreadcrumbData', [])
  }
})
// 跳转
const jumpHandle = (row, index) => {
  if (!row.pathName || (!row.pathName && index === breadcrumbData.value.length - 1)) return
  routerJumpChange({
    name: row.pathName,
    query: row.query
  })
  if (row.childrenList?.length) {
    sessionStorage.setItem('categoryChildren', JSON.stringify(row.childrenList))
  }
}
</script>

<style lang="scss" scoped>
.el-breadcrumb {
  margin-bottom: 16px;
  :deep(.el-breadcrumb__item) {
    &.isLink {
      .el-breadcrumb__inner {
        cursor: pointer;
      }
      &:hover {
        .el-breadcrumb__inner {
          color: (--breadcrumb-active-color,#2785dc);
          text-decoration: underline;
          font-weight: normal;
        }
      }
    }
    .el-breadcrumb__separator {
      font-weight: normal;
    }
    .el-breadcrumb__inner {
      --el-text-color-regular: var(--breadcrumb-color,#8f979f);
      --el-text-color-primary: var(--breadcrumb-color,#8f979f);
    }
  }
}
</style>
