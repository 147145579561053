import dayjs from './utilsDayjs'
import { floor, cloneDeep, isArray } from 'lodash'
import router from '@/router'
import store from '@/store'
import { fetchReq } from '@/utils/request/index'
import { ElLoading, ElMessage } from 'element-plus'
// 格式化时间
export function parseTime (time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string')) {
      if ((/^[0-9]+$/.test(time))) {
        time = parseInt(time)
      } else {
        // time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const timeStr = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    return value.toString().padStart(2, '0')
  })
  return timeStr
}

// 随机生成16位字符串
export function randomString (e) {
  e = e || 16
  const t = 'ABCDEFGHIJKLMNOPQRSTUVWWXYZabcdefghijklmnopqrstuvwwxyz0123456789'
  const a = t.length
  let n = ''
  for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a))
  return n
}
// 文字大于多少字节时用...省略
export const textOmit = (str = '', bitNum) => {
  let len = 0
  let newStr = ''
  const totalLen = str.replace(/[^x00-xff]/g, '00').length
  if (totalLen <= bitNum) return str
  const strArr = str.split('')
  for (let i = 0; i < strArr.length; i++) {
    const elLen = strArr[i].replace(/[^x00-xff]/g, '00').length
    if (len < bitNum) {
      len += Number(elLen)
      newStr += strArr[i]
    } else {
      newStr += '...'
      break
    }
  }
  return newStr
}
// 请求数据成功后，对于数据的处理
export const restReqSuccData = async (resMe, params, callBack, isSubmit) => {
  if (isSubmit) {
    store.commit('app/SET_RESPONSE_DATA', {
      submit: true
    })
  }
  try {
    const { requestMethod = '', ...otherParams } = params || {}
    const resData = await resMe(otherParams, requestMethod)
    const { code, data, msg } = resData
    const msgSplit = msg ? msg.split('：') : ''
    const sendMsg = code !== 0 ? msgSplit[msgSplit.length - 1] || msg : ''
    if (callBack) callBack(code === 0 ? (data || '') : null, sendMsg)
    store.commit('app/SET_RESPONSE_DATA', {
      status: 200,
      emptyText: sendMsg,
      submit: false
    })
  } catch (error) {
    if (error?.code === 'ERR_NETWORK') {
      store.commit('app/SET_RESPONSE_DATA', {
        status: 500,
        emptyText: '未连接到互联网，请检查网络',
        submit: false
      })
    }
    console.log('error', error)
    if (callBack) callBack(null, error.msg)
  }
}

// 获取两时间之前相差的天数
export const diffTimeDay = (newTime, oldTime) => {
  if (!newTime || !oldTime) {
    console.error('缺少时间')
    return
  }
  return dayjs(newTime).diff(oldTime, 'day')
}
// 获取两时间之前的差的显示
export const diffTimeShow = (time, maxDay = 10) => {
  const diffDay = diffTimeDay(new Date(), time)
  return diffDay > 10 ? dayjs(time).format('YYYY-MM-DD') : dayjs(time).fromNow()
}

// 转换首字母为小写
export const formatLower = (str) => {
  return str.charAt(0).toLowerCase() + str.slice(1)
}
export const isPro = () => {
  const isPro = process.env.VUE_APP_TITLE === 'production'
  const hostName = window.location.hostname
  return hostName.includes('eim.longsailing.net') || isPro
}
// 获取预览地址
export const getPreviewUrl = (fileNo, fileMd5, src = '') => {
  if (src) {
    let newSrc = src
    if (newSrc.indexOf('test.oss') > -1 && newSrc.startsWith('http://')) {
      newSrc = newSrc.replace('http://', 'https://')
    }
    return newSrc
  }
  const hostName = window.location.hostname
  const isPro = process.env.VUE_APP_TITLE === 'production'
  const isDev = process.env.VUE_APP_TITLE === 'development'
  const apiApi = isDev ? location.protocol + window.location.host + '/' : process.env.VUE_APP_BASE_API
  const md5Url = process.env.VUE_APP_MD5_URL
  const ossUrl = !fileMd5 ? '' : (md5Url + '/' + fileMd5)
  const localUrl = !fileNo ? '' : `${apiApi}base/fileView/preview/${fileNo}/sowoll`
  return (hostName.includes('eim.longsailing.net') || isPro) ? fileMd5 ? ossUrl : localUrl : localUrl
}
// 文件预览地址
export const getFilePreviewUrl = (fileNo, fileName, _fileMd5, fileUrl) => {
  if (fileUrl) {
    return fileUrl
  }
  // const hostName = window.location.hostname
  // const isPro = process.env.VUE_APP_TITLE === 'production'
  const isDev = process.env.VUE_APP_TITLE === 'development'
  const apiApi = isDev ? location.protocol + window.location.host + '/' : process.env.VUE_APP_BASE_API
  // const md5Url = process.env.VUE_APP_MD5_URL
  // const ossUrl = !fileNo ? '' : `https://oss.longsailing.net/base/fileView/preview/${fileNo}/${fileName}`
  const localUrl = !fileNo ? '' : `${apiApi}base/fileView/preview/${fileNo}/${fileName || 'sowoll'}`
  return localUrl
}
export const debounce = (func, wait = 100) => { // 防抖
  let timeout
  return function () {
    const context = this
    const args = arguments
    const later = () => {
      timeout = null
      func.apply(context, args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}
// 获取文件后缀名
export const getSuffix = (name = '') => {
  const lastIndex = name.lastIndexOf('.')
  let suffixName = ''
  let result = ''
  if (lastIndex > -1) {
    suffixName = name.substring(lastIndex + 1).toLowerCase()
    suffixName = suffixName.replace(/<[^<>]+>/g, '')
    const imgList = allFilesSuffix().img
    const pptList = allFilesSuffix().ppt
    const videoList = allFilesSuffix().video
    result = imgList.includes(suffixName)
      ? 'img'
      : pptList.includes(suffixName)
        ? 'ppt'
        : suffixName.includes('xls')
          ? 'xls'
          : suffixName.includes('doc')
            ? 'docx'
            : videoList.includes(suffixName) ? 'video' : suffixName
  }
  return {
    suffix: result,
    origin: suffixName
  }
}
// 路由跳转
export const routerJumpChange = ({ path = '', name, params = {}, query = {}, newWindow, isReplace }) => {
  if (!name && !path) {
    console.error('缺少路由')
    return
  }
  const routerData = {
    path,
    name,
    params: params || {},
    query: query || {}
  }
  if (!newWindow) {
    if (isReplace) {
      router.replace(routerData)
    } else {
      router.push(routerData)
    }
  } else {
    openNewRouter(path || '', name, params || {}, query || {})
  }
}

// 新开路由页面
export const openNewRouter = (path, name, params = {}, query = {}) => {
  const routeData = router.resolve({ path, name, params, query })
  window.open(routeData.href, '_blank')
}
// 文件下载
let loadingInstance = null
export const fileDownLoad = async (url, fileName) => {
  if (!fileName || !url) {
    console.error('缺少文件名/地址')
    return
  }
  if (url.indexOf(window.location.hostname) < 0) {
    if (url.indexOf('test.oss') > -1 && url.startsWith('http://')) {
      url = url.replace('http://', 'https://')
    }
    try {
      loadingInstance = ElLoading.service({ fullscreen: true, background: 'rgba(0,0,0,0.3)', text: '获取资源中...' })
      const blob = await fetchReq({
        url,
        method: 'GET',
        otherConfig: {
          responseType: 'blob'
        }
      })
      url = window.URL.createObjectURL(blob)
    } catch (error) {
    }
  }
  const a = document.createElement('a')
  a.style.display = 'none'
  a.setAttribute('target', '_blank')
  if (fileName) {
    a.setAttribute('download', fileName)
  }
  a.href = url
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  loadingInstance && loadingInstance.close()
}
// 图片校验-校验图片大小，尺寸，类型，比例
export const validImg = (file, acceptTypes, astrictSize, scale, measure) => {
  return new Promise((resolve) => {
    const name = file.name
    const type = name.slice(name.lastIndexOf('.') + 1)
    const curType = type.toLowerCase()
    const isAllow = !acceptTypes ? true : acceptTypes.includes(curType)
    const isLtSize = !astrictSize ? true : file.size / 1024 / 1024 < astrictSize
    if (!isLtSize) {
      resolve({
        result: false,
        err: 'size'
      })
    }
    if (!isAllow) {
      resolve({
        result: false,
        err: 'type'
      })
    }
    if (!scale && !measure) {
      resolve({
        result: true
      })
      return
    }
    getImgData(file, scale, measure).then(res => {
      resolve(res)
    })
  })
}
export const getImgData = (file, scale, measure) => {
  const scaleData = !scale ? '' : scale.split(':')
  const measureData = !measure ? '' : measure.split(',')
  return new Promise((resolve) => {
    const image = new Image()
    image.src = URL.createObjectURL(file)
    let imgWidth = null
    let imgHeight = null
    image.onload = () => {
      imgWidth = image.width
      imgHeight = image.height
      if (scaleData) {
        if (scaleData[0] && scaleData[1]) {
          const imgScale = imgHeight / imgWidth
          const compare = imgScale !== scaleData[0] / scaleData[1]
          resolve({
            result: !compare,
            err: compare ? 'scale' : ''
          })
        } else {
          console.error('scale传值不对')
          resolve({
            result: false,
            err: 'scale'
          })
        }
      } else {
        const validH = !measureData[1] ? true : imgHeight !== measureData[1]
        const validW = !measureData[0] ? true : imgWidth !== measureData[0]
        const compare = !validH || !validW
        resolve({
          result: !compare,
          err: compare ? 'measure' : ''
        })
      }

      URL.revokeObjectURL(image.src)
    }
    image.onerror = () => {
      resolve({
        result: false,
        err: 'noImg'
      })
    }
  })
}
// 返回上一页并关闭当前的标签
export const backHandleAndCloseTag = (routeName, backName, query = {}) => {
  try {
    const visitedViews = store.state.tagsView.visitedViews
    const view = visitedViews.find((v) => v.name === routeName)
    if (view) {
      store.dispatch('tagsView/delView', view).then(({ visitedViews }) => {
        if (backName) {
          router.push({ name: backName, query })
        } else {
          if (window.history) {
            window.history.go(-1)
          } else {
            router.go(-1)
          }
        }
      })
    }
  } catch (error) {
    console.log('error', error)
  }
}
// 数字转换-百/千/万转换
export const convertCount = (count = 0, unitVal = '千') => {
  if (!count) return 0
  const typeMap = new Map([
    ['千', {
      base: 1000,
      max: 9999,
      unit: 'K'
    }],
    ['万', {
      base: 10000,
      max: 999999,
      unit: 'W'
    }]
  ])
  const { base, unit } = typeMap.get(unitVal)
  if (count < base) {
    return count
  } else {
    const num = count / base
    return `${floor(num, 1)}${unit}`
  }
}
// 从html里获取图片地址集合
export const getSourceSrcListFromHtml = (nodeHtml = '', img = true, video = false) => {
  // 匹配图片，视频
  const imgReg = /<img.*?(?:>|\/>)/gi
  const srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i
  const videoReg = /<video(([\s\S])*?)<\/video>/gi
  const arrMap = {
    img: [],
    video: []
  }
  const urlsList = {
    img: [],
    video: []
  }
  if (img) {
    arrMap.img = nodeHtml.match(imgReg)
  }
  if (video) {
    arrMap.video = nodeHtml.match(videoReg)
  }
  Object.keys(arrMap).forEach(k => {
    const list = arrMap[k]
    if (list?.length) {
      // 匹配src属性
      for (let i = 0; i < list.length; i++) {
        const src = list[i].match(srcReg)
        // 获取图片地址
        if (src[1]) {
          urlsList[k].push(src[1])
        }
      }
    }
  })
  return urlsList
}
// 提取富文本里面的图片和视频
export const extractEditorFiles = (editorConfig, olFiles = [], srcName = 'src', sourceType = '') => {
  const allNewFileList = []
  let arr = []
  const needSource = !sourceType ? ['image', 'video'] : sourceType
  let allFileList = []
  needSource.forEach(item => {
    const list = editorConfig.value.getElemsByType(item) || []
    allFileList = allFileList.concat(list)
  })
  if (allFileList.length) {
    allFileList.forEach((item) => {
      const obj = {
        fileName: '',
        md5: '',
        fileNo: '',
        src: '',
        type: ''
      }
      obj.src = item.src || ''
      if (obj.src) {
        const splitUrl = obj.src.split('/')
        obj.fileName = splitUrl[splitUrl.length - 1] || null
      }
      if (item.alt || item.poster) {
        arr = (item.alt || item.poster).split('/')
        obj.fileName = arr[0] || obj.fileName
        obj.md5 = arr[1]
        obj.fileNo = arr[2]
        const type = arr[arr.length - 1]
        if (type === 'uploadH') {
          obj.type = 'upload'
        }
      }
      if (olFiles && olFiles.length) {
        const findData = olFiles.find(oldItem => (oldItem.fileNo === arr[2] || oldItem[srcName] === item.src))
        if (findData?.id) {
          obj.id = findData.id
        }
      }
      allNewFileList.push(obj)
    })
  }
  return allNewFileList
}
export const allFilesSuffix = () => {
  const typeList = {
    img: ['jpg', 'jpeg', 'png', 'gif'],
    ppt: ['ppt', 'pptx'],
    video: ['swf', 'avi', 'flv', 'mpg', 'rm', 'mov', 'wav', 'asf', '3gp', 'mkv', 'rmvb', 'mp4', 'm4v'],
    audio: ['mp3', 'mpc'],
    docx: ['docx', 'doc'],
    xls: ['xls', 'xlsx'],
    zip: ['zip', 'tar', 'rar', 'gzip', 'bzip2'],
    pdf: ['pdf']
  }
  return {
    ...typeList
  }
}
export const checkIsSupportTypeImg = (type = '') => {
  const list = allFilesSuffix()
  const newType = type.toLowerCase()
  const filter = Object.keys(list).filter(key => {
    return key === newType || list[key].includes(newType)
  })
  return ((filter && filter[0]) || 'other')
}
// 根据文件名，文件地址获取对应文件类型图片
export const getFileTypeImg = (fileName = '', fileUrl = '') => {
  const urlSuffix = getSuffix(fileUrl)
  const name = getSuffix(fileName)
  const type =
    name?.suffix ||
    urlSuffix?.suffix ||
    (fileUrl ? 'video' : 'text')
  return {
    type,
    origin: name?.origin || urlSuffix?.origin,
    img: checkIsSupportTypeImg(type) + '.svg'
  }
}
// 阿拉伯文转中文
/**
 * number: 数字
 * isAbbreviation：是否转为中文数字简写
 */
export const numberToTextNumber = (number, isAbbreviation = true) => {
  if (number === '') return ''
  const stringNumber = String(number).split('')
  const numberText = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十']
  const complexFont = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖', '拾']
  const baseText = [null, { num: 10, text: '十' }, { num: 100, text: '百' }, { num: 1000, text: '千' }, { num: 10, text: '万' }]
  const mapData = isAbbreviation ? numberText : complexFont
  if (stringNumber.length === 1) {
    return mapData[Number(number)]
  }
  const base = baseText[stringNumber.length - 1]
  const baseNumText = (String(number / base.num).replace('.', base.text)).split('')
  let str = ''
  if (baseNumText.length === 1) {
    return (Number(baseNumText) === 1 && stringNumber.length === 2 ? '' : mapData[Number(baseNumText)]) + base.text
  }
  for (const i in baseNumText) {
    if ((Number(i) === 0 && (Number(baseNumText[i]) !== 1 || base.num !== 10)) || Number(i) > 0) {
      str += mapData[Number(baseNumText[i])] || baseNumText[i]
    }
  }
  return str
}
export const openFilePicker = ({ fn, acceptList = ['png', 'jpg', 'jpeg'], size = 10, multiple } = {}) => {
  const inpEle = document.createElement('input')
  inpEle.id = 'jsUpload_image_uploads'
  inpEle.type = 'file'
  inpEle.style.display = 'none'
  // 文件类型限制
  acceptList && (inpEle.accept = acceptList.map(item => ('.' + item)).join(','))
  // 多选限制
  multiple && (inpEle.multiple = multiple)
  inpEle.addEventListener(
    'change',
    async (event) => {
      const file = inpEle.files[0]
      const result = await validImg(file, acceptList.join(','), size)
      if (result.err) {
        const err = result.err
        return ElMessage.error(err === 'size' ? `上传附件大小不能超过${size}MB！` : '不支持上传此格式的文件！')
      } else {
        fn(inpEle.files)
      }
    },
    { once: true }
  )
  inpEle.click()
}
// 通过配置项设置表单字段
export const setFormFiledFormConfig = (formConfig) => {
  const formData = {}
  for (const i in formConfig) {
    formData[formConfig[i].prop] = formConfig[i].defaultValue ?? ''
    if (formConfig[i].childProp) {
      for (const item in formConfig[i].childProp) {
        formData[formConfig[i].childProp[item]] = formConfig[i].childDefaultValue ?? ''
      }
    }
  }
  return formData
}
// 通过配置项里面的权限过滤不显示的配置
export const setInfoItemConfig = (detail = {}, infoConfig) => {
  const newConfig = {}
  const configIsArray = isArray(infoConfig)
  for (const j in infoConfig) {
    const infoItem = infoConfig[j]
    const isShow = !infoItem.authShow ? true : infoItem.authShow(detail)
    if (isShow) {
      newConfig[j] =
        typeof infoItem === 'string' ? infoItem : cloneDeep(infoItem)
      if (infoItem.labelFn) {
        newConfig[j].label = infoItem.labelFn(detail)
      }
      if (infoItem.msgFn) {
        newConfig[j].placeholder = infoItem.msgFn(detail)
      }
      if (infoItem.listNameFn) {
        newConfig[j].listName = infoItem.listNameFn(detail)
      }
      if (infoItem.disabledFn) {
        newConfig[j].disabled = infoItem.disabledFn(detail)
      }
      if (infoItem.optionsFn) {
        newConfig[j].options = infoItem.optionsFn(detail)
      }
    }
  }
  return !configIsArray ? newConfig : Object.values(newConfig)
}