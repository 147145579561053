<template>
  <div class="app-layout" :class="{flagBorder:isFlagBorder}">
    <div class="top-nav">
      <TopNav />
    </div>
    <div class="cnt-box">
      <div
        class="left-menu"
        :class="{ 'collapse-menu': !state.opened }"
      >
        <Sidebar />
      </div>
      <div
        class="hamburger"
        :class="{ 'open-hamburger': !state.opened }"
        @click="toggleMenu"
      >
        <img
          :src="require(`@/assets/images/menu/${state.opened ? 'left' : 'right'}.svg`)"
        >
      </div>
      <div class="main-box">
        <RouterView v-slot="{ Component, }">
        <keep-alive :include="includeList">
          <component :is="Component"></component>
        </keep-alive>
      </RouterView>
        <WaterMark class="layOut-globalMark"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import { TopNav, Sidebar } from './components'
import WaterMark from '@/components/WaterMark'
// import { mapState } from 'vuex'
import { RouterView } from 'vue-router'
import { reactive, computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const state = reactive({
  opened: true
})
const includeList = computed(() => {
  return store.state.app.releaseInclude
})
const isFlagBorder = computed(() => store.state.app.releasePageConfig.flagBorder)
const toggleMenu = () => {
  state.opened = !state.opened
  store.dispatch('app/toggleSideBar', state.opened)
}
</script>
<style lang="scss" scoped>
.app-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  &.flagBorder{
    :deep(.tag-box),
    :deep(.main-box){
      border-left:1px solid #E5E6EB;
    }
  }
  .cnt-box {
    flex: 1;
    overflow: hidden;
    display: flex;
    justify-content: stretch;
    // align-items: center;
    position: relative;
    .left-menu {
      height: 100%;
      width: 240px;
      overflow-x: hidden;
      overflow-y: auto;
      background: #f7f8fc;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        position: relative;
        display: block;
        width: 0;
        height: 0;
        cursor: pointer;
        border-radius: inherit;
        background-color: rgba(144, 147, 153, 0.3);
        transition: 0.3s background-color;
      }
      &.collapse-menu {
        width: 72px;
        :deep(li) {
          width: 40px;
          height: 32px;
          text-align: center;
          .el-submenu__icon-arrowm,
          span {
            display: none;
          }
        }
      }
    }
    .main-box {
      flex: 1;
      background-color: #fff;
      margin: 0;
      display: flex;
    flex-direction: column;
      padding: 0;
      overflow: auto;
      :deep(.el-scrollbar__wrap) {
        overflow-x: hidden;
      }
    }
    .main-box>div:first-child{
      flex:1;
    }
    .hamburger {
      width: 20px;
      height: 42px;
      flex-shrink: 0;
      display: inline-block;
      vertical-align: middle;
      line-height: 80px;
      z-index: 3;
      border-radius: 0 4px 4px 0;
      cursor: pointer;
      position: absolute;
      left: 218px;
    }
    .open-hamburger {
      left: 50px;
    }
  }
}
</style>