import baseApi from '@/api/base'
const state = {
  sidebar: {
    opened: true
  },
  device: 'desktop',
  sPartWidth: 0,
  tPartHeight: 0,
  clientActiveMenu: '首页',
  clientPageConfig: {
    searchHide: false,
    pageBg: '',
    theme: '',
    showBar: false,
    breadcrumb: false,
    headerMenu: true
  },
  releasePageConfig: {},
  clientPageTheme: '',
  excludes: '',
  releaseInclude: [],
  kbmsMenu: {},
  reqData: {
    responseStatus: 200,
    submitStatus: false,
    emptyText: ''
  },
  breadcrumbData: [],
  privacyPolicyDialog: {
    show: false
  }
}

const mutations = {
  TOGGLE_SIDEBAR: (state, payload) => {
    state.sidebar.opened = !state.sidebar.opened
  },
  SET_SPART_WIDTH: (state, payload) => {
    state.sPartWidth = payload
  },
  SET_TPART_HEIGHT: (state, payload) => {
    state.tPartHeight = payload
  },
  SET_CLIENT_MENU_ACTIVE: (state, active) => {
    state.clientActiveMenu = active
  },
  SET_CLIENT_PAGE: (state, config) => {
    const headerMenu = config.headerMenu === undefined ? true : config.headerMenu
    state.clientPageConfig = {
      ...config,
      headerMenu
    }
  },
  SET_RELEASE_PAGE: (state, config) => {
    state.releasePageConfig = config
  },
  SET_MENU: (state, data) => {
    state.kbmsMenu = data
  },
  SET_RESPONSE_DATA: (state, { status = 200, submit = false, emptyText = '' }) => {
    state.reqData = {
      responseStatus: status,
      submitStatus: submit,
      emptyText
    }
  }
}

const actions = {
  toggleSideBar: ({ commit }, payload) => {
    commit('TOGGLE_SIDEBAR', payload)
  },
  setTPartHeight: ({ commit }, payload) => {
    commit('SET_TPART_HEIGHT', payload)
  },
  setClientActiveMenu: ({ commit }, active) => {
    commit('SET_CLIENT_MENU_ACTIVE', active)
  },
  setClientPage: ({ commit }, config) => {
    commit('SET_CLIENT_PAGE', config)
  },
  setReleasePage: ({ commit }, config) => {
    commit('SET_RELEASE_PAGE', config)
  },
  // 添加需要缓存的组件
  setExcludes ({ state }, name) {
    const oldExcludes = [...state.excludes]
    if (!oldExcludes.includes(name)) {
      oldExcludes.push(name)
      state.excludes = oldExcludes
    }
  },
  setReleaseInclude ({ state }, name) {
    const oldInclude = [...state.releaseInclude]
    if (!oldInclude.includes(name)) {
      oldInclude.push(name)
      state.releaseInclude = oldInclude
    }
  },
  removeReleaseIncludeItem ({ state }, name) {
    const oldInclude = [...state.releaseInclude]
    const index = oldInclude.indexOf(name)
    if (index > -1) {
      oldInclude.splice(index, 1)
    }
    state.releaseInclude = oldInclude
  },
  // 获取菜单信息
  getMenuList: ({ commit }) => {
    return new Promise((resolve, reject) => {
      baseApi.menuList().then(({ code, data }) => {
        if (code === 0) {
          const kbmsMenu = (data || []).find(v => v.resourceCode === 'InformationReleasePlatform')
          commit('SET_MENU', kbmsMenu || {})
          localStorage.setItem('kbmsMenu', JSON.stringify(kbmsMenu || {}))
          resolve()
        }
      })
        .catch(error => {
          reject(error)
        })
    })
  },

  // 设置面包屑数据
  setBreadcrumbData: ({ state }, list = []) => {
    state.breadcrumbData = list
  },
  // 若用户还未阅读过隐私协议，操作隐私协议弹窗
  openPrivacyPolicyDialog: ({ state }, isShow) => {
    state.privacyPolicyDialog.show = isShow
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
