export const staticTabs = [
  // {
  //   label: '全部消息',
  //   name: 'allMsg',
  //   value: null
  // },
  {
    label: '互动',
    name: 'interaction',
    typeList: [1, 2, 3, 4],
    value: 'interaction',
    pathName: 'MessageCenter'
  }
]
export const authTabs = {
  articleAudit: {
    label: '文章审批',
    type: 'release_audit',
    name: 'articleAudit',
    pathName: 'ArticleAudit',
    detailPath: 'CreateDocument',
    tabTitle: '审批文章',
    dispatchName: 'user/getArticleAuditCountData'
  },
  courseAudit: {
    label: '课程审批',
    type: 'release_audit',
    name: 'courseAudit',
    pathName: 'CourseAudit',
    detailPath: 'CourseDetail',
    tabTitle: '审批课程',
    dispatchName: 'user/getCourseAuditCountData'
  }
}
export const enterNames = {
  interaction: '消息中心',
  articleAudit: '文章审核',
  courseAudit: '课程审核'
}