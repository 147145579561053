<template>
  <div class="infoItem flex align-center" @click="toDetail">
    <div class="leftInfo">
      <el-badge is-dot class="item" :hidden="props.row?.isRead">
        <Avatar :src="avatarUrl" />
      </el-badge>
    </div>
    <div class="rightInfo">
      <div class="rightTop flex flex-between align-center">
        <span class="userName text-ellipsis">{{
          props.row?.createdName || '匿名用户'
        }}</span>
        <span class="time">{{ diffTimeShow(props.row?.createdTime) }}</span>
      </div>
      <div class="content webkit-ellipsis">
        <template v-if="props.row?.actionType !== 4">
          {{ actionTypeName[props.row?.actionType] }}了我的{{
            linkTypeName[props.row?.linkType]?.name
          }}：《{{ props.row?.linkTitle }}》
        </template>
        <template v-else>
          在《{{ props.row?.linkTitle }}》{{
            actionTypeName[props.row?.actionType]
          }}了我
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import Avatar from '@/components/Comment/Avatar'
import { computed } from 'vue'
import { useStore } from 'vuex'
import { getPreviewUrl, routerJumpChange, diffTimeShow } from '@/utils'
import {
  actionTypeName,
  linkTypeName
} from '@/views/personalCenter/messageCenter/use/staticData'

const props = defineProps({
  row: {
    type: Object,
    default: () => {}
  }
})
const store = useStore()
const avatarUrl = computed(() =>
  getPreviewUrl(props.row?.avatarFileNo, props.row?.avatarMd5)
)
const jumpMap = {
  1: 'ClientArticleDetail',
  2: 'ClientTopicPostDetail',
  3: 'ClientCourseDetail'
}
const toDetail = () => {
  if (!props.row.isRead) {
    store.dispatch('user/readMsg', {
      id: props.row.id
    })
  }
  routerJumpChange({
    name: jumpMap[props.row.linkType],
    params: {
      id: props.row.linkId
    },
    newWindow: true
  })
  if (props.row.actionType === 4) {
    localStorage.setItem('toComment', true)
  }
}
</script>

<style lang="scss" scoped>
.infoItem {
  margin-bottom: 16px;
  padding-bottom: 4px;
  cursor: pointer;
  gap:12px;
  --avatar-w: 32px;
  --avatar-h: 32px;
  --avatar-mr:0;
  .leftInfo{
    --el-color-danger: #eb5757;
    .el-badge{
      :deep(.el-badge__content){
        &.is-fixed.is-dot{
          right: 9px;
          top: 3px;
          width:10px;
          height:10px;
        }
      }
    }
  }
  .rightInfo {
    flex: 1;
    overflow: hidden;
    font-size: 14px;
    line-height: 22px;
    .rightTop {
      padding-right: 10px;
      gap: 20px;
    }
    .userName {
      color: #585e67;
      flex: 1;
      overflow: hidden;
    }
    .time {
      color: #8f979f;
    }
    .content {
      color: #262626;
      -webkit-line-clamp: var(--comment-title-line-clamp, 3);
    }
  }
}
</style>
