import {
  removeOtherToken
} from '@/utils/auth'
// 首页文章模块
export const articleNames = {
  notice: {
    title: '重要通知',
    en: 'NOTIFICATION',
    id: '',
    pageSize: 6
  },
  dynamics: {
    title: '公司动态',
    en: 'COMPANY DYNAMICS',
    id: '',
    pageSize: 6
  },

  // institution: {
  //   title: '规章制度',
  //   en: 'INSTITUTION',
  //   id: '',
  //   pageSize: 6
  // },
  // train: {
  //   title: '培训中心',
  //   en: 'corporate training',
  //   id: '',
  //   pageSize: 12
  // },
  activity: {
    title: '乐在长帆',
    en: 'CULTURAL ACTIVITY',
    id: '',
    pageSize: 4
  },
  succReport: {
    title: '捷报速递',
    id: '',
    pageSize: 15
  }
}
// 全民营销和献策
export const imgAndTxt = [{
  title: '建言献策',
  subTitle: '为公司指点江山 军功章有你一半～',
  btnText: '留下建议',
  titleImg: 'suggest',
  bgUrl: 'client/suggestBg.svg',
  className: 'sugg'
}, {
  title: '全民营销',
  subTitle: '成功推荐客户线索 首单成交得奖金',
  btnText: '我要推荐',
  titleImg: 'marketing',
  bgUrl: 'client/marketingBg.svg'
}]
export const fastNavigation = [{
  title: 'OSS业务系统',
  url: 'https://oss.longsailing.net/#/dashboard',
  test_url: 'http://lstest.com/web-main/#/dashboard',
  uat_url: 'http://mng-uat-test.longsailing.net:8688/web-main/#/dashboard',
  test_root_domain: '.lstest.com',
  uat_root_domain: '.longsailing.net',
  root_domain: '.longsailing.net'
},
{
  title: '大禹数据平台',
  systemName: 'gotoBigData',
  url: 'https://bigdata.longsailing.net/'
}, {
  title: '海外系统',
  url: 'https://global.longsailing.net/#/home',
  test_url: 'http://global.lstest.com/#/home',
  uat_url: 'http://global.lsuat.com/#/home',
  test_root_domain: '.lstest.com',
  uat_root_domain: '.lsuat.com',
  root_domain: '.longsailing.net'
}]
export const removeJumpSetToken = () => {
  const env = process.env.VUE_APP_TITLE
  fastNavigation.forEach((el) => {
    const elCurrentDomain = el[`${env}_root_domain`] || el.root_domain
    if (elCurrentDomain) {
      removeOtherToken(elCurrentDomain)
    }
  })
}