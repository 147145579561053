<template>
  <div class="menu-item">
    <template v-if="subMenu.subResource && subMenu.subResource.length">
      <el-sub-menu
        :index="subMenu.resourceCode"
        :class="{'hide-icon': !isOpened }"
      >
        <template #title>
          <el-badge is-dot :hidden="(!childHasCount(subMenu.resourceCode))">
          <img
            v-if="subMenu.iconSrc"
            :src="require('@/assets/images/menu/'+subMenu.iconSrc+'.svg')"
            class="menu-icon"
          />
        </el-badge>
          <span>{{ subMenu.resourceName }}</span>
        </template>
        <div
          v-for="(item, index) in subMenu.subResource"
          :key="index"
        >
          <sidebar-item
            :subMenu="item"
            :activeIndex="activeIndex"
            :activeCode="activeCode"
            @setActiveCode="getActiveCode"
            :allMenu="allMenu"
          />
        </div>
      </el-sub-menu>
    </template>
    <template v-else>
      <el-menu-item
        :index="subMenu.resourceCode"
        @click="goSub(subMenu)"
      >
        <img
          v-if="subMenu.iconSrc"
          :src="require('@/assets/images/menu/'+subMenu.iconSrc+'.svg')"
          class="menu-icon"
        />
        <el-badge :value="badgeData[subMenu.resourceCode]?.count"
        :hidden="!badgeData[subMenu.resourceCode]?.count||!isOpened"
        :is-dot="!isOpened" :max="99" class="item">
        <span>{{ subMenu.resourceName }}</span>
      </el-badge>
      </el-menu-item>
    </template>
  </div>
</template>

<script>
import router from '@/router'

export default {
  name: 'sidebar-item',
  props: {
    subMenu: {
      type: Object,
      default: () => { }
    },
    activeIndex: {
      type: String,
      default: () => 'home'
    },
    allMenu: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      state: {},
      activeCode: ''
    }
  },
  created () {
    this.activeCode = this.activeIndex
    this.getActiveCode(this.activeCode)
  },
  computed: {
    isOpened () {
      return this.$store.state.app.sidebar.opened
    },
    badgeData () {
      return this.$store.state.user.badgeData
    }
  },
  methods: {
    goSub (item) {
      this.activeCode = item.resourceCode
      this.$emit('setActiveCode', this.activeCode)
      router.push({ path: item.routeCode })
    },
    getActiveCode (code) {
      this.$emit('setActiveCode', this.activeCode)
      for (let i = 0; i < this.allMenu.length; i++) {
        const item = this.allMenu[i]
        if (item?.subResource?.length) {
          const subResource = item.subResource
          for (let j = 0; j < subResource.length; j++) {
            const subItem = subResource[j]
            if (subItem.resourceCode === code && subItem.parentCode) {
              item.iconSrc = subItem.parentCode + '_active'
              return
            } else {
              item.iconSrc = subItem.parentCode
            }
          }
        } else {
          if (code === item.resourceCode) {
            item.iconSrc = item.resourceCode + '_active'
            return
          } else {
            item.iconSrc = item.resourceCode
          }
        }
      }
    },
    childHasCount (resourceCode) {
      const findIndex = Object.values(this.badgeData).findIndex(item => {
        return item.parentCode === resourceCode && item.count > 0
      })
      return findIndex > -1
    }
  },
  mounted () {
    this.getActiveCode(this.activeCode)
    // this.state = actions.getGlobalState()
  }
}
</script>
<style lang="scss" scoped>
@mixin menuIcon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
@mixin menuTextClass {
  color: #262626;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}
@mixin menuHover {
  border-radius: 4px;
  background: #f2f3f5;
}
@mixin activeColor {
  color: #2785dc;
}
.menu-item {
  --el-color-danger:#EB5757;
  :deep(.el-menu) {
    background: none !important;
    border-right: none;
    .el-menu-item {
      padding-left: 50px !important;
      color: #585e67;
      .el-badge{
        --el-badge-size:16px;
        .el-badge__content.is-fixed{
          top:50%;
          right:-4px;
        }
      }
      &:hover {
        @include menuHover;
      }
      &.is-active {
        @include activeColor;
        background: rgba(88, 94, 103, 0.04);
      }
    }
  }
  .el-sub-menu {
    &.is-active {
      :deep(.el-sub-menu__title) {
        @include activeColor;
      }
    }
    .el-badge{
        :deep(.el-badge__content.is-fixed){
          top: 6px;
          right: 21px;
        }
      }
    :deep(.el-sub-menu__title) {
      @include menuTextClass;
      .menu-icon {
        @include menuIcon;
      }
      &:hover {
        @include menuHover;
      }
    }
  }
}
.el-menu-item:hover {
  @include menuHover;
}

.el-menu-item {
  @include menuTextClass;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 224px;
  height: 48px;
  padding: 11px 16px !important;
  &.is-active {
    @include activeColor;
    background: rgba(88, 94, 103, 0.04);
  }
  .menu-icon {
    @include menuIcon;
  }
}
.hide-icon {
  :deep(.el-sub-menu__icon-arrow) {
    display: none !important;
  }
  :deep(.is-active) {
    background: #f7f8fc !important;
  }
}
</style>