<template>
  <div
    class="privacyPolicyDialog"
    :class="{ isDialog: props.isDialog }"
    v-if="show"
  >
    <div class="box-overlay">
      <div class="overlay-header">{{ title }}</div>
      <div class="overlay-body">
        <div class="content-detail">
          <h3>{{ title }}</h3>
          <div class="detainInfo">
            <div
              class="infoItem"
              v-for="(item, index) in infoData"
              :key="index"
            >
              <h4>{{ item.title }}</h4>
              <div v-html="item.desc"></div>
            </div>
            <div class="footerInfo flex">
              <p>制度编号：LS-HR</p>
              <p>编制部门：人力资源中心</p>
              <p>修订日期：2023-12-20</p>
            </div>
          </div>
        </div>
      </div>
      <div class="overlay-footer flex align-center">
        <el-button @click="closeDialog">{{
          hasAgreedProtocol ? '关闭' : '我要退出'
        }}</el-button>
        <el-button
          type="primary"
          v-if="!hasAgreedProtocol"
          :loading="loading"
          @click="confirmHandle"
          >{{
            hasAgreedProtocol
              ? ''
              : `知晓并同意 ${timeNum > 0 ? timeNum + 's' : ''}`
          }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import infoData from './staticData'
import { restReqSuccData } from '@/utils'
import commonApi from '@/api/common'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
const props = defineProps({
  isDialog: {
    type: Boolean,
    default: true
  }
})
const store = useStore()
const router = useRouter()
const show = computed(() => store.state.app.privacyPolicyDialog.show)
const hasAgreedProtocol = computed(
  () => store.getters.userInfo.hasAgreedProtocol
)
const title = ref('信息发布平台管理规定')
const timeNum = ref(10)
const loading = ref(false)
let timer = null
let bodyDom = null

const setTime = () => {
  if (!timer && timeNum.value > 0) {
    timer = setInterval(() => {
      timeNum.value--
      if (timeNum.value === 0) {
        clearInterval(timer)
      }
    }, 1000)
  } else {
    if (timer) {
      clearInterval(timer)
    }
  }
}
watchEffect(() => {
  if (show.value) {
    nextTick(() => {
      bodyDom = document
        .querySelector('.privacyPolicyDialog')
        ?.querySelector('.overlay-body')
      if (bodyDom) {
        bodyDom.scrollTop = 0
        if (!timer && !hasAgreedProtocol.value) {
          setTimeout(() => {
            setTime()
          }, 1000)
        }
      }
    })
  } else {
    if (timer) {
      clearInterval(timer)
    }
  }
})

// 确认操作
const confirmHandle = () => {
  if (timeNum.value > 0) {
    ElMessage.warning('请仔细阅读10s以上')
    return
  }
  if (loading.value) return
  loading.value = true
  restReqSuccData(commonApi.agreeProtocol, { requestMethod: 'get' }, (res) => {
    if (res) {
      store.commit('user/SET_USERINFO', {
        ...store.getters.userInfo,
        hasAgreedProtocol: true
      })
      localStorage.setItem(
        'userInfo',
        JSON.stringify({
          ...store.getters.userInfo,
          hasAgreedProtocol: true
        })
      )
      store.dispatch('app/openPrivacyPolicyDialog', false)
      if (window.location.hash.indexOf('/privacyPolicy') > -1) {
        router.replace('/client/clientHome')
      }
    }
    loading.value = false
  })
}
// 关闭操作
const closeDialog = () => {
  if (!hasAgreedProtocol.value) {
    store.dispatch('user/logout')
  } else {
    store.dispatch('app/openPrivacyPolicyDialog', false)
  }
}
</script>

<style lang="scss">
.privacyPolicyDialog {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &.isDialog {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 4vh 0 4vh;
  }
  .box-overlay {
    width: 1260px;
    height: 100%;
    overflow: hidden;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    .overlay-header {
      color: #262626;
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
      height: 56px;
      padding: 16px 24px;
      background-color: #fff;
    }
    .overlay-body {
      background-color: #f7f8fc;
      padding: 32px;
      flex: 1;
      overflow-y: auto;
      .content-detail {
        padding: 40px;
        box-shadow: 0px 4px 12px 0px #e0e3eb;
        background-color: #fff;
        h3 {
          font-size: 24px;
          line-height: 24px;
          text-align: center;
          margin-bottom: 40px;
          color: #262626;
        }
        .detainInfo {
          font-size: 14px;
          line-height: 28px;
          color: #262626;
          .infoItem {
            margin-bottom: 40px;
            h4 {
              text-align: center;
              font-size: 18px;
              margin-bottom: 20px;
            }
            .notice {
              color: #f00;
            }
            .weight {
              font-weight: bold;
            }
          }
          .footerInfo {
            flex-direction: column;
            align-items: flex-end;
            p {
              width: 160px;
            }
          }
        }
      }
    }
    .overlay-footer {
      height: 46px;
      background-color: #f2f3f5;
      border-top: 1px solid #d9d9d9;
      align-items: center;
      justify-content: center;
      .el-button,
      .el-button.el-button--primary {
        background-color: transparent;
        height: 32px;
      }
      .el-button {
        border-color: #585e67;
        color: #585e67;
        &.el-button--primary {
          border-color: #2785dc;
          color: #2785dc;
          &.is-disabled {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
</style>
