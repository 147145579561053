// 审核相关接口
import request from '@/api'

const auditApi = {}
const urls = [
  { api: 'articleList', path: 'article/list' }, // 审核文章查询
  { api: 'articleInfo', path: 'article/info' }, // 审核文章详情
  'list', // 审核记录查询
  'pass', // 审核通过
  'reject', // 审核拒绝
  'submit', // 提交审核
  { api: 'update', path: 'article/update' }, // 编辑
  'publish', // 文章上架
  'withdraw', // 文章下架
  { api: 'articleCommentPublish', path: 'article/comment/publish' }, // 文章评论上架
  { api: 'articleCommentWithdraw', path: 'article/comment/withdraw' }, // 文章评论下架
  { api: 'articleTop', path: 'article/top' }, // 文章置顶
  { api: 'articleUnTop', path: 'article/un-top' }, // 文章取消置顶
  { api: 'articleFeature', path: 'article/feature' }, // 文章设置精华
  { api: 'articleUnFeature', path: 'article/un-feature' }, // 文章取消设置精华
  // 贴子相关操作
  { api: 'postWithdraw', path: 'post/withdraw' }, // 贴子下架
  { api: 'postPublish', path: 'post/publish' }, // 贴子上架
  { api: 'postCommentWithdraw', path: 'post/comment/withdraw' }, // 贴子评论下架
  { api: 'postCommentPublish', path: 'post/comment/publish' }, // 贴子评论上架
  { api: 'postTop', path: 'post/top' }, // 贴子置顶
  { api: 'postUnTop', path: 'post/un-top' }, // 贴子取消置顶
  { api: 'postFeature', path: 'post/feature' }, // 贴子设置精华
  { api: 'postUnFeature', path: 'post/un-feature' }, // 贴子取消设置精华
  { api: 'auditCount', path: 'article/count' }, // 待审核数量
  { api: 'commentTop', path: 'article/comment/top' }, // 评论置顶
  { api: 'commentUnTop', path: 'article/comment/un-top' }, // 评论取消置顶
  { api: 'commentPostTop', path: 'post/comment/top' }, // 帖子评论置顶
  { api: 'commentPostUnTop', path: 'post/comment/un-top' } // 帖子评论取消置顶
]

urls.forEach((url) => {
  auditApi[url.api || url] = (data, method) => {
    const config = {
      url: `/kbms/audit/${url.path || url}`,
      method: method || 'post',
      data
    }
    return request(config)
  }
})
export default auditApi