<template>
  <div class="clientHeader">
    <div class="headerBox">
      <p class="logo"></p>
      <div class="menuList">
        <template v-for="item in pageMenuList" :key="item.pathName">
          <p
            class="menuItem"
            :class="{ active: activeMenu == item.title }"
            @click="jumpHandle(item)"
          >
            {{ item.title }}
          </p>
        </template>
      </div>
      <div class="rightBox">
        <el-input
          v-model="searchTxt"
          v-if="!props.searchHide"
          class="searchInput"
          placeholder="搜索"
          autocomplete="new-password"
          type="text"
          @keyup.enter="searchHandle"
          :readonly="isReadonly"
          @focus="changeReadonly(false)"
          @blur="changeReadonly(true)"
        ></el-input>
        <div class="userInfo">
          <NavBtn isHome />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { menuList } from './use/staticData'
import NavBtn from '../topNav/navbtn.vue'
const store = useStore()
const activeMenu = computed(() => store.state.app.clientActiveMenu)
const pageMenuList = ref(menuList.filter(item => item.client === undefined || item.client))

const router = useRouter()
const props = defineProps({
  searchHide: Boolean
})
const searchTxt = ref('')
// 刷新页面获取title设置当前在哪个菜单下
const { meta } = useRoute()
store.dispatch('app/setClientActiveMenu', meta.moduleName || meta.title)

// 菜单点击跳转
const jumpHandle = (item) => {
  store.dispatch('app/setClientActiveMenu', item.title)
  router.push({ name: item.pathName, query: item?.query || {}})
}
// 搜索
const isReadonly = ref(true)
const changeReadonly = (val) => {
  isReadonly.value = val
}
const searchHandle = () => {
  router.push({
    name: 'ClientSearchResult',
    query: {
      searchTxt: searchTxt.value
    }
  })
}
</script>

<style lang="scss">
.clientHeader {
  width: 100%;
  height: 64px;
  background-color: var(--client-header-bg, #2785dc);
  display: flex;
  justify-content: center;
  .headerBox {
    width: 1280px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      width: 152px;
      height: 30px;
      background-image: (
        var(--client-header-logo, url('@/assets/images/common/logoWrite.svg'))
      );
      background-size: cover;
    }
    .menuList {
      flex: 1;
      display: inline-flex;
      margin: 0 40px;
      overflow: auto;
      white-space: pre;
      height: 100%;
      align-items: center;
      justify-content: center;
      .menuItem {
        font-size: 16px;
        color: var(--client-header-menu-color, #fff);
        line-height: 28px;
        margin-right: 40px;
        cursor: pointer;
        &:last-child{
          margin-right: 0;
        }
        &.active {
          font-weight: 600;
        }
      }
    }
    .rightBox {
      display: flex;
      align-items: center;
      .searchInput {
        --el-input-width: 260px;
        --el-input-height: 40px;
        display: var(--search-input-display, inline-flex);
        margin-right: 40px;
        :deep(.el-input__inner) {
          -webkit-text-security: disc !important;
        }
      }
      .userInfo {
        display: flex;
        align-items: center;
      }
    }
  }
}
.userDropDown-pop {
  .el-scrollbar {
    padding: 2px;
  }
  .el-dropdown-menu__item {
    color: #262626;
    --el-dropdown-menuItem-hover-color: #262626;
  }
}
</style>
