<template>
  <div class="tags-cnt">
    <el-scrollbar
      ref="scrollContainer"
      class="tag-srollbar-cnt"
    >
      <ul id="tags">
        <li
          v-for="(item, index) in visitedViews"
          :key="index"
          @click="goSub(item)"
          :class="{
            'tag-active':
            item.path === curPath
          }"
        >
          <span
            class="tag-title"
            v-html="item.title"
          ></span>
          <el-icon
            class="el-icon-close"
            v-show="visitedViews.length > 1 || index !== 0"
            @click.stop="close(item)"
          >
            <Close />
          </el-icon>
        </li>
      </ul>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  watch: {
    $route ({ name, meta, path }) {
      this.setMetaTitle(name)
      // const addTagsRoute = ['/basicSetting', '/infoManagement', '/infoRelease', '/trainCenter']
      // if (addTagsRoute.some(v => !path.includes(v))) {
      if (!path.includes('client')) {
        this.addTags()
      }
    }
  },
  mounted () {
    this.addTags()
  },
  computed: {
    visitedViews () {
      return this.$store.state.tagsView.visitedViews || []
    },
    curPath () {
      return this.decodePath(this.$route.path)
    },
    curTagTitle () {
      return this.$store.state.tagsView.curTagTitle || sessionStorage.getItem('createDocTagName') || '创建文章'
    },
    articleTitle () {
      return (type) => {
        return `<b>[${type}]</b> ${this.$route.params.title}` || `${type}详情`
      }
    }
  },
  methods: {
    decodePath (path) {
      const pathArr = path.split('/')
      const pathSplit = window.decodeURI ? pathArr.map(item => window.decodeURI(item)) : pathArr
      return pathSplit.join('/')
    },
    addTags () {
      const { name, path } = this.$route
      if (name) {
        this.setMetaTitle(name)
        this.$store.dispatch('tagsView/addView', {
          ...this.$route,
          path: this.decodePath(path)
        })
      }
      return false
    },
    setMetaTitle (name) {
      if (['CreateLinkDocument', 'CreateDocument'].includes(name) || this.$route.meta.resetTagTitle) {
        this.$route.meta.title = this.curTagTitle
      }
      if (this.$route.meta.tagTitleSuffix && this.$route.params.title) {
        this.$route.meta.title = this.articleTitle(this.$route.meta.tagTitleSuffix)
      }
      if (['ArticleDetail', 'DocumentDetail'].includes(name)) {
        this.$route.meta.title = this.articleTitle('文章')
      }
      if (['PostDetail'].includes(name)) {
        this.$route.meta.title = this.articleTitle('贴子')
      }
    },
    goSub (item) {
      this.$router.push({
        name: item.name,
        query: item.query,
        params: item.params
      })
    },
    isActive (route) {
      return route.path === this.decodePath(this.$route.path)
    },
    close (view) {
      this.$store.dispatch('tagsView/delView', view).then(({ visitedViews }) => {
        if (this.isActive(view)) {
          this.toLastView(visitedViews, view)
        }
      })
    },
    toLastView (visitedViews) {
      const latestView = visitedViews.slice(-1)[0]
      if (latestView) {
        this.$router.push(latestView.fullPath)
      } else {
        this.$router.push({ name: 'ArticleRelease' })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.tags-cnt {
  width: 100%;
  overflow: auto;
  ul {
    display: flex;
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      color: #585e67;
      height: 65px;
      line-height: 64px;
      cursor: pointer;
      padding: 0 5px;
      flex-shrink: 0;
      font-size: 14px;
      width: auto;
      display: flex;
      align-items: center;
      padding: 0 8px;
      margin-left: 8px;
      i {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        text-align: center;
        margin-left: 8px;
      }
      i:hover {
        background-color: #b4bccc;
        color: #fff;
      }
      &.tag-active {
        border-top: 2px solid #2785dc;
        background: #f7f8fc;
        color: #2785dc;
        font-weight: 400;
        line-height: 62px;
        height: 63px;
      }
      .tag-title {
        display: inline-block;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .contextmenu {
    display: flex;
    flex-direction: column;
    margin: 0;
    background: #fff;
    position: fixed;
    z-index: 3;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    -webkit-box-shadow: 2px 2px 3px 0 rgb(0 0 0 / 30%);
    box-shadow: 2px 2px 3px 0 rgb(0 0 0 / 30%);
    width: 80px;
    li {
      color: #333;
      cursor: pointer;
      margin: 0;
      &:hover {
        background-color: #eee;
      }
    }
  }
}
</style>