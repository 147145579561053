// axios封装
import axios from 'axios'
import requestDataHandle from './requestDataHandle'
import responseHandle from './responseHandle'
import responseErrHandle from './responseErrHandle'
window.isRefreshing = false

axios.defaults.baseURL = '/'
const baseUrl = process.env.NODE_ENV === 'production' ? location.origin + '/' : process.env.VUE_APP_BASE_API // url = base url + request url
const timeOut = 500 * 1000 // request timeout  因为附件信息有视频上传，加了一个零
const service = axios.create({
  // 外网地址，baseURL暂使用域名
  baseURL: baseUrl,
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: timeOut
  // timeout: 5 // 强制超时，用来测试
})

// request interceptor
service.interceptors.request.use(
  config => {
    const { newConfig, defaultParams, isFormData } = requestDataHandle(config)
    if (newConfig.method === 'post') {
      newConfig.data = isFormData ? defaultParams : Object.assign(defaultParams, { data: config.data })
    }
    if (newConfig.method === 'get') {
      Object.assign(newConfig.params || {}, defaultParams)
    }
    return newConfig
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    // 文件下载时，response.data为blob格式，无code
    if (response.config.responseType === 'blob') {
      const contentDisposition = response.headers['content-disposition']
      const contentDispositionArr = contentDisposition?.split(';') || []
      let fileName = ''
      if (contentDispositionArr.length > 0) {
        contentDispositionArr.forEach(item => {
          if (item.indexOf('fileName') > -1) {
            const splitStr = item.split('=')
            const fileNameUrlCode = splitStr[splitStr.length - 1]
            fileName = fileNameUrlCode ? window.decodeURI(fileNameUrlCode) : ''
          }
        })
      }
      return {
        code: 0,
        data: {
          blobData: new Blob([response.data]),
          fileName: fileName || ''
        }
      }
    }
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    return responseHandle(res)
  },
  error => {
    return responseErrHandle(error)
  }
)
export default service
export const fetchReq = (config) => {
  try {
    const { url, method, data, otherConfig = {}} = config
    const { newConfig, defaultParams } = requestDataHandle(config)
    const params = {
      data,
      ...defaultParams
    }
    const configHeaders = newConfig?.headers || {}
    const headersData = {
      'Content-Type': 'application/json',
      ...configHeaders
    }
    const isBlob = otherConfig?.responseType === 'blob'
    return new Promise((resolve, reject) => {
      const fetchConfig = {
        method,
        ...otherConfig,
        headers: headersData,
        keepalive: true,
        timeout: timeOut
      }
      if (['post', 'POST'].includes(method)) {
        fetchConfig.body = JSON.stringify(params)
      }
      fetch(url, fetchConfig)
        .then(response => {
          return isBlob ? response.blob() : response.json()
        })
        .then(res => {
          resolve(isBlob ? new Blob([res]) : responseHandle(res))
        }).catch(error => {
          reject(responseErrHandle(error))
        })
    })
  } catch (error) {
    console.log('error', error)
  }
}

