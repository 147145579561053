
import request from '@/api'

const baseApi = {}
const urls = [
  { api: 'login', path: 'common/login' }, // 登录
  { api: 'loginOut', path: 'common/logout' }, // 退出登录
  { api: 'sysUserInfo', path: 'webapi/sysUser/info' }, // 获取用户信息
  { api: 'deptList', path: 'webapi/dept/list' }, // 获取部门信息
  { api: 'listByRole', path: 'webapi/role/employees/listByRole' },
  { api: 'employeeList', path: 'webapi/dept/employee/list' }, // 查询员工信息
  { api: 'employeeInfo', path: 'webapi/dept/employee/info' }, // 获取员工信息
  { api: 'menuList', path: 'webapi/menu/allMenuCheckAuth' } // 获取菜单信息
]

urls.forEach((url) => {
  baseApi[url.api || url] = (data, method) => {
    const config = {
      url: `/base/${url.path || url}`,
      method: method || 'post',
      data
    }
    return request(config)
  }
})
export default baseApi