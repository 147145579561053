<template>
  <div v-popover="popoverRef" v-click-outside="onClickOutside">
    <slot name="popBtn"></slot>
  </div>
  <el-popover
    ref="popoverRef"
    :width="props.width"
    popper-class="customPopover"
    :placement="placement"
    :trigger="props.trigger"
    :disabled="disabled"
    virtual-triggering
    :teleported="props.insertBody"
    persistent
    show-arrow
    @show="popShow"
    @hide="popHide"
  >
    <slot></slot>
  </el-popover>
</template>

<script setup>
import { ref, unref } from 'vue'
import { ClickOutside as vClickOutside } from 'element-plus'
const popoverRef = ref()
const onClickOutside = () => {
  unref(popoverRef).popperRef?.delayHide?.()
}
const props = defineProps({
  disabled: Boolean,
  width: {
    type: Number,
    default: 300
  },
  trigger: {
    type: String,
    default: 'click'
  },
  placement: {
    type: String,
    default: 'bottom'
  },
  insertBody: {
    type: Boolean,
    default: true
  }
})
const popoverHide = () => {
  unref(popoverRef)?.hide()
}
const popShow = () => {
  if (document.getElementById('appVue')) {
    document.getElementById('appVue').onmousewheel = () => {
      popoverHide()
    }
  }
}
const popHide = () => {
  if (document.getElementById('appVue')) {
    document
      .getElementById('appVue')
      .removeEventListener('onmousewheel', () => {})
  }
}
defineExpose({
  popHide: popoverHide
})
</script>

<style lang="scss">
.el-popover.customPopover {
  padding: 0;
}
</style>
