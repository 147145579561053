<template>
  <el-container class="clientView-layout" :class="clientPageConfig.theme">
    <el-header v-if="headerMenuShow">
      <client-header :searchHide="clientPageConfig.searchHide" />
    </el-header>
    <el-main
      class="layout-mainInfo"
      id="mainInfo"
      :class="{ showBar: clientPageConfig.showBar,'showHeader': headerMenuShow }"
    >
      <Breadcrumb />
      <RouterView v-slot="{ Component }">
        <keep-alive :exclude="excludes">
          <component :is="Component"></component>
        </keep-alive>
      </RouterView>
      <WaterMark  class="layOut-globalMark"/>
      <div class="layout-footerInfo mb-40">
        Copyright©1999-{{ years }} All Rights Reserve 深圳市硕沃网络科技有限公司
      </div>
    </el-main>
  </el-container>
  <PrivacyPolicyDialog />
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { RouterView } from 'vue-router'
import WaterMark from '@/components/WaterMark'
import ClientHeader from './components/clientHeader/index'
import Breadcrumb from './components/breadcrumb'
import PrivacyPolicyDialog from './components/privacyPolicyDialog'
const store = useStore()
const pageBg = computed(() => {
  return store.state.app.clientPageConfig.pageBg || '#f7f8fc'
})
const headerMenuShow = computed(() => store.state.app.clientPageConfig.headerMenu)
const excludes = computed(() => {
  return store.state.app.excludes
})
const clientPageConfig = computed(() => store.state.app.clientPageConfig)
const years = ref()
const nowYear = new Date().getFullYear()
const nowYMonth = new Date().getMonth() + 1
years.value = nowYMonth > 11 ? nowYear + 1 : nowYear
</script>

<style lang="scss">
.el-input,
.el-textarea {
  --el-input-focus-border-color: #e5e6eb;
  --el-border-color-hover: #e5e6eb;
}
.el-textarea__inner {
  resize: none;
}
.clientView-layout {
  height: 100%;
  background-color: v-bind(pageBg);
  --notice-w: 17px;
  --notice-h: 20px;
  --notice-img: url('@/assets/images/common/notification.svg');
  --client-header-icon-color: 1;
  --notice-margin: 0 26px 0 0;
  .layout-mainInfo {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    padding:0;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    overflow: auto;
    &.showHeader{
      padding: 20px 0 0;
    }

    &.showBar {
      &::-webkit-scrollbar {
        display: block;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
  .el-header {
    --el-header-padding: 0;
    width: 100%;
    z-index: 11;
    --el-header-height: 64px;
  }
  .layout-footerInfo {
    color: #8f979f;
    font-size: 14px;
    line-height: 36px;
    text-align: center;
    margin-top: 50px;
  }
}
</style>
