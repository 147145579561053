import { getToken } from '@/utils/auth'
import router from '@/router/index.js'
import userApi from '@/api/user'
import store from '@/store/index'
import { getPreviewUrl } from './index'
import { validPrivacyPolicy } from './privacyPolicy'
// const ISCHROMIUM = navigator.userAgent.includes('AppleWebKit')
const whiteList = ['/login'] // no redirect whitelist

router.beforeEach((to, from, next) => {
  if (window.cf_pageNoLoad) {
    // window.alert('请使用谷歌Chrome浏览器或微软Edge浏览器！')
    next({ name: 'NotFound' })
    return
  }
  const token = getToken()
  // 将token缓存一下
  const storageToken = sessionStorage.getItem('token')
  const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null
  // 用户若没有同意用户协议
  if (userInfo?.hasAgreedProtocol === false) {
    store.dispatch('app/openPrivacyPolicyDialog', true)
  }
  if (token) {
    const isRefreshUserInfo = !userInfo || userInfo.hasAgreedProtocol === undefined
    // 没有缓存token或缓存的token不是最新的（oss那边切换了用户信息）都需要更新用户信息
    if (!storageToken || (storageToken && token !== storageToken) || isRefreshUserInfo) {
      sessionStorage.setItem('token', token)
      userApi.userInfo(token, 'GET')
        .then(async (res) => {
          if (res.code === 0) {
            const userInfo = res.data
            userInfo.avatar = getPreviewUrl(res.data?.avatarFileNo, res.data?.avatarMd5)
            store.commit('user/SET_USERINFO', userInfo)
            localStorage.setItem('userInfo', JSON.stringify(userInfo))
            sessionStorage.setItem('token', token)
            store.commit('tagsView/RESET_VISITED_VIEW')
            // 用户若没有同意用户协议
            if (!userInfo.hasAgreedProtocol) {
              store.dispatch('app/openPrivacyPolicyDialog', true)
            }
            // 登录的情况下，获取oss配置的菜单权限
            await store.dispatch('app/getMenuList')
            next()
          }
        })
        .catch(() => { })
        .finally(() => { })
    } else {
      // 若用户在没有同意协议情况下，暴力隐藏弹窗，对提示做二次处理
      const privacyPolicyResult = validPrivacyPolicy(store.state.app.privacyPolicyDialog.show)
      if (!privacyPolicyResult && to.path !== '/privacyPolicy') {
        return next({ path: '/privacyPolicy', replace: true })
      } else {
        if (to.path === '/login') {
          // login，跳转默认首页
          next({ path: '/' })
        } else {
          next()
          const keepAlive = to.meta.keepAlive === undefined ? true : to.meta.keepAlive
          if (!keepAlive && to.name) {
            store.dispatch('app/setExcludes', to.name)
          }
          store.dispatch('app/setClientPage', to.meta)
          if (to.path.includes('/client/')) {
            document.title = '长帆-企业信息平台'
          } else {
            document.title = '信息发布平台'
            store.dispatch('app/setReleasePage', to.meta)
          }
          const mainId = document.getElementById('mainInfo')
          if (mainId) {
            setTimeout(() => {
              mainId.scrollTop = 0
            }, 500)
          }
          if (to.meta.keepAlive === true && !(to.path.includes('/client/'))) {
            store.dispatch('app/setReleaseInclude', to.name)
          }
        }
      }
    }
  } else {
    // 未登录
    if (whiteList.indexOf(to.path) !== -1) {
      // 免登陆白名单内，直接进入
      next()
    } else {
      // 否则全部重定向到登录页
      // next(`/login?redirect=${to.path}`)
      next(`/login?redirect=${to.path}`)
    }
    // 没有登录，跳转至oss的登录页面去
    // location.href = process.env.NODE_ENV === 'development' ? '/#/login' : process.env.VUE_APP_BASE_API + '#/login'
  }
})
