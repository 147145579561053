import { ref, markRaw, reactive } from 'vue'
import { menuData } from '@/views/personalCenter/data'
import { formatLower } from '@/utils'

export const useData = () => {
  const activeName = ref('')
  const tabList = ref([])
  // 获取tab标签信息
  const getTabList = (curRouteName = 'messageCenter') => {
    const find = markRaw(menuData).find(
      (item) => item.resourceCode === formatLower(curRouteName)
    )
    if (find) {
      return find.tabList.filter((v) => !v.isHide)
    } else {
      return []
    }
  }
  const params = reactive({
    actionType: null
  })
  // 切换tab标签回调
  const changeTab = (name) => {
    const find = tabList.value.find((item) => item.name === name)
    if (find) {
      params.actionType = find.value
    }
    activeName.value = name
  }
  const dataInit = (newValue = 'messageCenter') => {
    tabList.value = getTabList(newValue)
    if (tabList?.value?.length) {
      activeName.value = tabList.value[0].name
      changeTab(tabList.value[0].name)
    } else {
      activeName.value = ''
    }
  }
  return {
    params,
    activeName,
    tabList,
    dataInit,
    changeTab,
    getTabList
  }
}