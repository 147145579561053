import { ElMessage } from 'element-plus'

const responseErrHandle = (error) => {
  if (error.message && error.message === 'Network Error') {
    return Promise.reject(error)
  } else {
    console.error(error) // for debug
    if (error.message && error.message.indexOf('timeout') > -1) {
      error = { msg: '请求超时！' }
    }
    ElMessage({
      message: error.msg || error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
}
export default responseErrHandle