export const actionTypeName = {
  1: '收藏',
  2: '点赞',
  3: '评论',
  4: '评论中@'
}
export const linkTypeName = {
  1: {
    source: '信息',
    name: '作品'
  },
  2: {
    source: '论坛',
    name: '贴子'
  }
}