import store from '@/store'
const tagsView = {
  state: {
    visitedViews: [],
    curTagTitle: ''
  },
  mutations: {
    ADD_VISITED_VIEW: (state, view) => {
      if (state.visitedViews.some(v => v.path === view.path)) {
        state.visitedViews.forEach(item => {
          if ((item.name === view.name && view.name === 'CreateDocument')) {
            item.title = state.curTagTitle
          }
        })
        return
      }
      state.visitedViews.push(
        Object.assign({}, view, {
          title: view.meta.title || 'no-name'
        })
      )
    },
    DEL_VISITED_VIEW: (state, view) => {
      for (const [i, v] of state.visitedViews.entries()) {
        if (v.path === view.path) {
          state.visitedViews.splice(i, 1)
          break
        }
      }
    },
    RESET_VISITED_VIEW: (state) => {
      state.visitedViews = []
    },
    SET_CREATE_DOC_TAG_NAME: (state, title) => {
      state.curTagTitle = title
    }
  },
  actions: {
    addView ({ dispatch }, view) {
      dispatch('addVisitedView', view)
    },
    addVisitedView ({ commit }, view) {
      commit('ADD_VISITED_VIEW', view)
    },
    delView ({ dispatch, state }, view) {
      return new Promise(resolve => {
        dispatch('delVisitedView', view)
        store.dispatch('app/removeReleaseIncludeItem', view.name)
        resolve({
          visitedViews: [...state.visitedViews]
        })
      })
    },
    delVisitedView ({ commit, state }, view) {
      return new Promise(resolve => {
        commit('DEL_VISITED_VIEW', view)
        resolve([...state.visitedViews])
      })
    },
    // 设置发布信息tag名
    setCreateDocTagName ({ commit }, title) {
      commit('SET_CREATE_DOC_TAG_NAME', title)
      sessionStorage.setItem('createDocTagName', title)
    }
  },
  namespaced: true
}

export default tagsView