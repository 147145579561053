export const menuData = [
  {
    resourceCode: 'companyPush',
    resourceName: '公司推送',
    routeCode: '/client/personalCenter/companyPush',
    iconSrc: 'companyPush',
    tabList: [
      {
        label: '部门推送',
        name: 'deptPush'
      },
      {
        label: '岗位推送',
        name: 'jobPush'
      }
    ]
  }, {
    resourceCode: 'relateMe',
    resourceName: '与我相关',
    routeCode: '/client/personalCenter/relateMe',
    iconSrc: 'relateMe',
    tabList: [
      {
        label: '我的收藏',
        name: 'myCollection'
      }, {
        label: '我的评论',
        name: 'myComment'
      }, {
        label: '我的点赞',
        name: 'myThumbs'
      }, {
        label: '浏览记录',
        name: 'browsingHistory'
      }
    ]
  }, {
    resourceCode: 'forumPostBar',
    resourceName: '论坛贴吧',
    routeCode: '/client/personalCenter/forumPostBar',
    iconSrc: 'forumPostBar',
    tabList: [
      {
        label: '我的发布',
        name: 'myRelease',
        listMeName: 'myPostList'
      }, {
        label: '我的收藏',
        name: 'myCollection',
        listMeName: 'myPostCollectList'
      }, {
        label: '我的评论',
        name: 'myComment'
      }, {
        label: '我的点赞',
        name: 'myThumbs',
        listMeName: 'myPostLikeList'
      }, {
        label: '浏览记录',
        name: 'browsingHistory',
        listMeName: 'postBrowseHistory'
      }
    ]
  },
  {
    resourceCode: 'personalCenterExamCenter',
    resourceName: '考试中心',
    routeCode: '/client/personalCenter/clientExamCenter',
    iconSrc: 'clientExam',
    tabList: [
      {
        label: '待考试',
        name: 'pending'
      },
      {
        label: '已完成',
        name: 'complete'
      }
    ]
  },
  {
    resourceCode: 'personalCenterStudyCenter',
    resourceName: '学习中心',
    routeCode: '/client/personalCenter/clientStudyCenter',
    iconSrc: 'clientStudy',
    tabList: [
      {
        label: '必修课',
        name: 'myPush',
        badgeName: 'myPush',
        tabHasFilter: true,
        tabList: [{
          label: '系列课程',
          value: true
        },
        {
          label: '课程',
          value: false,
          tabList: [{
            label: '待学习',
            badgeName: 'learn_1',
            value: 0
          }, {
            label: '学习中',
            badgeName: 'learn_2',
            value: 1
          }, {
            label: '已完成',
            badgeName: 'learn_3',
            value: 2
          }]
        }
        ]
      }, {
        label: '学习记录',
        name: 'browsingHistory',
        options: [{
          label: '全部',
          value: 'all'
        },
        {
          label: '近半年',
          value: 'halfYear'
        },
        {
          label: '近一年',
          value: 'year'
        },
        {
          label: '去年',
          value: 'lastYear'
        }
        ]
      }, {
        label: '我的收藏',
        name: 'myCollection'
      }, {
        label: '我的点赞',
        name: 'myThumbs'
      }, {
        label: '我的评论',
        name: 'myComment'
      }
    ]
  },
  {
    resourceCode: 'messageCenter',
    resourceName: '消息中心',
    routeCode: '/client/personalCenter/messageCenter',
    iconSrc: 'messageCenter',
    tabList: [
      {
        label: '全部消息',
        name: 'allMsg',
        value: null
      }, {
        label: '点赞',
        name: 'thumbs',
        value: 2
      }, {
        label: '收藏',
        name: 'collection',
        value: 1
      }, {
        label: '评论',
        name: 'comment',
        value: 3
      }, {
        label: '@我的',
        name: 'refer',
        value: 4
      }, {
        label: '系统通知',
        name: 'sysNotification',
        isHide: true
      }
    ]
  }, {
    resourceCode: 'modifyInfo',
    resourceName: '修改资料',
    routeCode: '/client/personalCenter/modifyInfo',
    iconSrc: 'modifyInfo',
    tabList: [
      {
        label: '基础信息',
        name: 'basicInfo'
      }
    ]
  }
]