<template>
  <div class="messageInfo flex">
    <TabList
      :active="activeName"
      :tabList="tabsList"
      showNum
      :numList="messageTotal"
      @change="changeTab"
    ></TabList>
    <div class="scrollList">
      <div
        class="loadMoreList"
        ref="loadMoreListRef"
        v-infinite-scroll="scrollLoad"
        :infinite-scroll-distance="5"
        :infinite-scroll-immediate="false"
      >
        <template v-for="item in currentList" :key="item.id">
          <template v-if="activeName !== 'interaction'">
            <p class="auditItem">
              {{ item.authorName || item.createdName }}发起的【{{ item.title || item.name }}】,请点击<span
                @click="toAuditDetail(item)"
                >这里</span
              >及时审批。
            </p>
          </template>
          <template v-else>
            <MessageInfoItem :row="item" />
          </template>
        </template>
        <LoadingOrEmpty
          :noMore="noMore"
          :emptyShow="!currentList.length"
          emptyText="暂无消息"
          :imgSize="340"
        />
      </div>
    </div>
    <div class="actionBtn flex align-center flex-between">
      <div>
        <el-button
          text
          @click="readAll"
          v-if="activeName === 'interaction' && allTypeList.interaction.length"
          >全部已读</el-button
        >
      </div>
      <el-button v-if="enterNames[activeName]" text @click="enterCenter"
        >进入{{ enterNames[activeName] }}
        <el-icon class="rightIcon"><ArrowRight /></el-icon
      ></el-button>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, watch, computed, watchEffect } from 'vue'
import TabList from '@/views/personalCenter/messageCenter/components/tabList'
import { useData } from '@/views/personalCenter/messageCenter/use/useData'
import LoadingOrEmpty from '@/components/ListWrap/loadingOrEmpty'
import MessageInfoItem from './messageInfoItem'
import { routerJumpChange } from '@/utils'
import { useStore } from 'vuex'
import { enterNames } from './use/staticData'
import { useRouter } from 'vue-router'
const store = useStore()
const router = useRouter()
const emits = defineEmits(['hide', 'setBadge'])
const { params, activeName, dataInit, changeTab } = useData()
// const tabsList = ref([])
dataInit()
const listData = computed(() => store.state.user.unreadMessageList)
const tabsList = computed(() => store.state.user.messageTabs)
// 获取权限内菜单
const kbmsMenu = computed(() => {
  return (
    JSON.parse(localStorage.getItem('kbmsMenu')) || store.state.app.kbmsMenu
  )
})
// 获取是否有查看后台的权限
const isShowManagement = computed(() => {
  return kbmsMenu.value && kbmsMenu.value?.subResource?.length
})
const scrollPageData = reactive({
  pageSize: 10,
  pageNum: 1
})
// 获取消息信息
const getList = () => {
  store.dispatch('user/getUnreadMsg', {
    ...params.value,
    pageSize: 1000,
    pageNum: 1,
    isRead: 0
  })
}
// 获取待审批的数量
const getPendingAuditCount = () => {
  for (const i in tabsList.value) {
    if (tabsList.value[i].dispatchName) {
      store.dispatch(tabsList.value[i].dispatchName, tabsList.value[i]?.listParams || {
        pageNum: 1,
        pageSize: 100,
        statusList: 'pending',
        needAudit: true
      })
    }
  }
}
const allTypeList = reactive({})
const messageTypeNums = reactive({})
const messageTotal = computed(() => {
  const articleNums = store.state.user.badgeData?.articleAudit?.count || 0
  const courseNums = store.state.user.badgeData?.courseAudit?.count || 0
  return {
    ...messageTypeNums,
    allMsg: (messageTypeNums.interaction || 0) + Number(articleNums),
    articleAudit: articleNums,
    courseAudit: courseNums
  }
})
const articleAuditList = computed(() => {
  return store.state.user.badgeData?.articleAudit?.list || []
})
const courseAuditList = computed(() => {
  return store.state.user.badgeData?.courseAudit?.list || []
})
// 当前列表-列表滚动
const currentList = ref([])
const loadMoreListRef = ref()
const noMore = ref(false)
const setList = () => {
  const listMap = {
    articleAudit: articleAuditList.value,
    courseAudit: courseAuditList.value
  }
  const list = listMap[activeName.value] || allTypeList[activeName.value] || []
  currentList.value = list.slice(
    0,
    scrollPageData.pageSize * scrollPageData.pageNum
  )
}
const scrollLoad = () => {
  const listPages = Math.ceil(
    messageTotal[activeName.value] / scrollPageData.pageSize
  )
  if (scrollPageData.pageNum < listPages) {
    scrollPageData.pageNum = scrollPageData.pageNum + 1
    setList()
  } else {
    noMore.value = true
  }
}
const readAll = () => {
  store.dispatch('user/readMsg')
}
// 跳转个人信息中心
const enterCenter = () => {
  if (activeName.value === 'interaction') {
    routerJumpChange({
      name: 'MessageCenter'
    })
  } else {
    toAuditDetail()
  }

  emits('hide')
}
// 跳转审批详情
const toAuditDetail = (row) => {
  const find = tabsList.value.find(item => item.name === activeName.value)
  store.dispatch('tagsView/setCreateDocTagName', find?.tabTitle)
  if (find?.detailPath || find?.pathName) {
    router.push({
      name: !row ? find?.pathName : find?.detailPath,
      query: { type: 'approve', id: row?.id },
      params: { id: row?.id }
    })
  }

  emits('hide')
}
watchEffect(() => {
  if (isShowManagement.value) {
    store.dispatch('user/getUserMessageTabs', kbmsMenu.value?.subResource || [])
    getList()
    getPendingAuditCount()
  }
})
watch(
  () => listData.value,
  (newVal = []) => {
    activeName.value = tabsList.value[0]?.name || 'interaction'
    allTypeList.interaction = newVal
    // tabsList.value.forEach((tabItem) => {
    //   if (tabItem.value === null) {
    //     allTypeList[tabItem.name] = newVal
    //   } else {
    //     allTypeList[tabItem.name] = newVal.filter((item) => {
    //       const types = tabItem.typeList || [tabItem.value]
    //       return types.includes(item.actionType)
    //     })
    //   }
    // })
    messageTypeNums.interaction = newVal.length
    setList()
  }
)
watch(
  () => activeName.value,
  (newVal) => {
    if (newVal) {
      scrollPageData.pageNum = 1
      noMore.value = false
      if (loadMoreListRef.value) loadMoreListRef.value.scrollTop = 0
      setList()
    }
  }
)
watch(
  () => messageTotal.value,
  (newVal) => {
    emits('setBadge', newVal?.allMsg || 0)
  }
)
</script>

<style lang="scss" scoped>
.messageInfo {
  height: 434px;
  flex-direction: column;
  .scrollList {
    flex: 1;
    overflow: hidden;
    padding: 0 24px 24px;
    .loadMoreList {
      height: 100%;
      overflow-y: auto;
      .auditItem {
        font-size: 14px;
        line-height: 22px;
        color: #262626;
        margin-bottom: 16px;
        span {
          color: #2785dc;
          cursor: pointer;
        }
      }
    }
  }
  .actionBtn {
    height: 48px;
    border-top: 1px solid #e5e6eb;
    .el-button {
      --el-button-text-color: #8f979f;
      --el-fill-color-light: transparent;
    }
    .rightIcon {
      margin-left: 12px;
    }
  }
}
</style>
