import store from '@/store'
import { ElMessage, ElMessageBox } from 'element-plus'

const responseHandle = (res) => {
  if (res.code !== 0) {
    // 单独处理提单保存验证返回的code 避免抛出错误提示
    if (res.code === 122087) {
      return Promise.resolve({ code: 122087, msg: res.msg })
    }
    ElMessage({
      message: res.msg || 'Error',
      type: 'error',
      // duration: 5 * 1000,
      showClose: true
    })

    // token验证失败，失效
    if (res.code === 100004 || res.code === 100005) {
      // to re-login
      ElMessageBox.confirm('请重新登录', '提示', {
        confirmButtonText: '去登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        store.dispatch('user/resetToken').then(() => {
          location.reload()
        })
      }).catch(() => { })
    }
    return Promise.resolve({ code: 122087, msg: res.msg })
  } else {
    return res
  }
}
export default responseHandle