export const menuList = ([
  {
    title: '首页',
    pathName: 'ClientHome'
  },
  {
    title: '长帆之家',
    pathName: 'LongSailHouse'
  },
  {
    title: '七彩长帆',
    pathName: 'ColorfulLongSail'
  },
  {
    title: '知识库',
    pathName: 'ClientMoreArticles',
    query: {
      title: '企业知识库',
      eName: 'Knowledge base',
      pageName: '知识库'
    }
  },
  {
    title: '公司制度',
    pathName: 'ClientCompanyInstitution'
  },
  {
    title: '长帆学堂',
    pathName: 'LongSailSchool'
  }
])