import { createStore } from 'vuex'
import getters from './getters'
import app from './modules/app'
import user from './modules/user'
import tagsView from './modules/tagsView'

export default createStore({
  modules: {
    app,
    // permission,
    user,
    tagsView
    // dict
  },
  getters
})