const trainCenterRoutes = [{
  path: 'trainCenter',
  name: 'TrainCenter',
  redirect: '/trainCenter/courseManagement',
  meta: { title: '课程管理' },
  children: [
    {
      path: 'courseRelease',
      name: 'CourseRelease',
      component: () => import('@/views/trainCenter/courseRelease'),
      meta: { title: '课程发布', keepAlive: true, isMenu: true }
    },
    {
      path: 'addCourse/:id?',
      name: 'AddCourse',
      component: () => import('@/views/trainCenter/courseRelease/addCourse/index'),
      meta: { title: '新增课程', resetTagTitle: true }
    },
    {
      path: 'addSeriesCourse/:id?',
      name: 'AddSeriesCourse',
      component: () => import('@/views/trainCenter/courseRelease/addCourse/addSeries'),
      meta: { title: '新增系列课程', isMenu: true }
    },
    {
      path: 'seriesCourseDetail/:id/:title?',
      name: 'SeriesCourseDetail',
      component: () => import('@/views/trainCenter/courseDetail/index'),
      meta: { title: '系列课程详情', resetTagTitle: true, tagTitleSuffix: '系列课程' }
    },
    {
      path: 'courseDetail/:id/:title?',
      name: 'CourseDetail',
      component: () => import('@/views/trainCenter/courseDetail/index'),
      meta: { title: '课程详情', resetTagTitle: true, tagTitleSuffix: '课程' }
    },
    {
      path: 'courseAudit',
      name: 'CourseAudit',
      component: () => import('@/views/trainCenter/courseAudit'),
      meta: { title: '课程审核', keepAlive: true, isMenu: true }
    },
    {
      path: 'courseManagement',
      name: 'CourseManagement',
      component: () => import('@/views/trainCenter/courseManagement'),
      meta: { title: '课程管理', isMenu: true }
    },
    {
      path: 'lecturerManagement',
      name: 'LecturerManagement',
      component: () => import('@/views/trainCenter/lecturerManagement'),
      meta: { title: '讲师列表', isMenu: true }
    },
    {
      path: 'trainPlan',
      name: 'TrainPlan',
      component: () => import('@/views/trainCenter/trainPlan'),
      meta: { title: '培训计划', isMenu: true, keepAlive: true }
    },
    {
      path: 'addPlan',
      name: 'AddPlan',
      component: () => import('@/views/trainCenter/trainPlan/addPlan/index'),
      meta: { title: '创建培训计划', isMenu: true, flagBorder: true, resetTagTitle: true }
    },
    {
      path: 'trainPlanPreview/:id/:title?',
      name: 'TrainPlanPreview',
      component: () => import('@/views/trainCenter/preview/trainPlan'),
      meta: { title: '预览培训计划', isMenu: true, resetTagTitle: true, tagTitleSuffix: '预览培训计划' }
    },
    {
      path: 'trainPlanDetail/:id/:title?',
      name: 'TrainPlanDetail',
      component: () => import('@/views/trainCenter/trainPlan/trainPlanDetail'),
      meta: { title: '培训计划详情', isMenu: true, resetTagTitle: true, tagTitleSuffix: '培训计划' }
    },
    {
      path: 'courseLearningCondition/:id/:title?',
      name: 'CourseLearningCondition',
      component: () => import('@/views/trainCenter/learningCondition'),
      meta: { title: '学习情况', isMenu: true, resetTagTitle: true, tagTitleSuffix: '学习情况' }
    }
  ]
}
]
export default trainCenterRoutes