const honorSystemRoutes = [{
  path: 'honorSystem',
  name: 'HonorSystem',
  redirect: '/honorSystem/points',
  meta: { title: '荣誉体系' },
  children: [
    {
      path: 'points',
      name: 'Points',
      component: () => import('@/views/honorSystem/points/index'),
      meta: { title: '积分明细', keepAlive: true, isMenu: true }
    }
  ]
}]
export default honorSystemRoutes