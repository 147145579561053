// 收藏夹相关接口
import request from '@/api'
import { fetchReq } from '@/utils/request/index'

const courseApi = {}
const urls = [
  'list', // 课程列表
  'saveAndSubmit', // 新增
  'delete', // 删除
  'update', // 保存
  { api: 'saveDraft', path: 'draft/save' }, // 保存草稿
  'info', // 详情
  'restore', // 恢复
  'trash', // 作废
  'search', // 搜索
  'read', // 阅读量
  { api: 'auditUpdate', path: 'audit/update' }, // 已发布课程修改
  { api: 'catalogList', path: 'publish/list', method: 'GET' }, // 课程可选择的目录
  { api: 'audit', path: 'audit/submit' }, // 提交课程审核
  { api: 'auditList', path: 'audit/list' }, // 审核列表
  { api: 'auditCount', path: 'audit/count' }, // 审核课程数量
  { api: 'auditInfo', path: 'audit/info' }, // 审核文章详情
  { api: 'auditPass', path: 'audit/pass' }, // 审核通过
  { api: 'auditInfoReject', path: 'audit/reject' }, // 审核拒绝
  { api: 'publish', path: 'audit/publish' }, // 课程上架
  { api: 'withdraw', path: 'audit/withdraw' }, // 课程下架
  { api: 'auditLogList', path: 'audit/auditList' }, // 审核记录查询
  { api: 'lecturerList', path: 'lecturer/list' }, // 讲师列表
  { api: 'addLecturer', path: 'lecturer/add' }, // 新增讲师
  { api: 'updateLecturer', path: 'lecturer/update' }, // 更新讲师
  { api: 'deleteLecturer', path: 'lecturer/delete' }, // 删除讲师
  { api: 'lecturerInfo', path: 'lecturer/info' }, // 讲师详情
  { api: 'setFeature', path: 'audit/feature' }, // 设置精华
  { api: 'unFeature', path: 'audit/un-feature' }, // 取消设置精华
  { api: 'deptPush', path: 'push/dept' }, // 部门推送
  { api: 'jobPush', path: 'push/job' }, // 岗位推送
  { api: 'pushList', path: 'push/user' }, // 推送课程
  { api: 'courseHistoryList', path: 'history/course/list', firstCatalog: 'browse' }, // 设置精华
  { api: 'courseDetailLecturerIList', path: 'lecturer/listInfo' }, // 课程详情-讲师列表
  { api: 'saveWatchTime', path: 'watch/save', requestMe: 'fetch' }, // 阅读推送课程(增加阅读时间)
  { api: 'userWatchTime', path: 'watch/user' }, // 用户课程及学习时长统计
  { api: 'watchList', path: 'watch/list' }, // 课程学习情况列表
  { api: 'itemCourseList', path: 'list', firstCatalog: 'itemCourse' }, // 课程分类列表
  { api: 'courseStudyList', path: 'watch/course/list' }, // 课程-学习记录
  { api: 'time', path: 'time', method: 'get' }, // 请求获取挂机时长
  { api: 'userPoints', path: 'user/userPoints', method: 'GET' }, // 用户积分查询
  { api: 'topRank', path: 'topRank', method: 'GET' }, // 课程前三
  { api: 'userTopRank', path: 'user/topRank', method: 'GET' }, // 用户前三
  { api: 'rank', path: 'rank' }, // 课程排行
  { api: 'userRank', path: 'user/userRank' }, // 用户排行
  { api: 'userCreditList', path: 'user/credits' }, // 用户课程学分明细
  { api: 'userPointsList', path: 'user/points' }, // 用户贡献积分明细
  { api: 'userHonorList', path: 'user/honorList' }, // 荣誉体系-积分明细
  { api: 'userHonorDetail', path: 'user/honorDetail' } // 荣誉体系-积分单个明细详情
]

urls.forEach((url) => {
  courseApi[url.api || url] = (data) => {
    const config = {
      url: `/kbms/${url.firstCatalog || 'course'}/${url.path || url}`,
      method: url.method || 'post',
      data
    }
    return url?.requestMe === 'fetch' ? fetchReq(config) : request(config)
  }
})
export default courseApi