export const validPrivacyPolicy = (show) => {
  if (!show) {
    return true
  }
  const dom = document.querySelector('.privacyPolicyDialog')
  if (dom) {
    const { display, visibility } = dom.style
    if (display === 'none') {
      dom.style.display = 'flex'
    }
    if (visibility === 'hidden') {
      dom.style.visibility = 'inherit'
    }
    return true
  } else {
    return false
  }
}