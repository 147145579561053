const infoManagementRoutes = [
  {
    path: 'infoRelease',
    name: 'InfoRelease',
    component: () => import('@/views/infoReleasePlatform/infoRelease'),
    redirect: '/infoRelease/articleRelease',
    meta: { title: '文章发布' },
    children: [
      {
        path: 'articleRelease',
        name: 'ArticleRelease',
        component: () => import('@/views/infoReleasePlatform/infoRelease/articleRelease'),
        meta: { title: '文章发布', isMenu: true }
      }, {
        path: 'createDocument',
        name: 'CreateDocument',
        component: () => import('@/views/infoReleasePlatform/infoRelease/articleRelease/createDocument/index'),
        meta: { title: '创建文章', isMenu: false }
      },
      {
        path: 'createLinkDocument',
        name: 'CreateLinkDocument',
        component: () => import('@/views/infoReleasePlatform/infoRelease/articleRelease/createLinkDocument'),
        meta: { title: '创建关联文章', isMenu: false }
      },
      {
        path: 'documentDetail/:id/:title',
        name: 'DocumentDetail',
        component: () => import('@/views/infoReleasePlatform/infoRelease/articleRelease/documentDetail'),
        meta: { title: '文章详情', isMenu: false }
      }, {
        path: 'articleAudit',
        name: 'ArticleAudit',
        component: () => import('@/views/infoReleasePlatform/infoRelease/articleAudit'),
        meta: { title: '文章审核', isMenu: true }
      }
    ]
  }, {
    path: 'infoManagement',
    name: 'InfoManagement',
    component: () => import('@/views/infoReleasePlatform/infoManagement'),
    redirect: '/infoManagement/articleManagement',
    meta: { title: '信息管理' },
    children: [
      {
        path: 'articleManagement',
        name: 'ArticleManagement',
        component: () => import('@/views/infoReleasePlatform/infoManagement/articleManagement'),
        meta: { title: '文章管理', isMenu: true }
      },
      {
        path: 'interpretManagement',
        name: 'InterpretManagement',
        component: () => import('@/views/infoReleasePlatform/infoManagement/interpretManagement'),
        meta: { title: '制度解读管理', isMenu: true }
      },
      {
        path: 'articleDetail/:id/:title',
        name: 'ArticleDetail',
        component: () => import('@/views/infoReleasePlatform/components/articleDetail'),
        meta: { title: '文章详情', isMenu: false }
      }, {
        path: 'postManagement',
        name: 'PostManagement',
        component: () => import('@/views/infoReleasePlatform/infoManagement/postManagement'),
        meta: { title: '贴子管理', isMenu: true }
      }, {
        path: 'postDetail/:id/:title',
        name: 'PostDetail',
        component: () => import('@/views/infoReleasePlatform/components/postDetail'),
        meta: { title: '贴子详情', isMenu: false }
      },
      {
        path: 'pushArticleManagement',
        name: 'PushArticleManagement',
        component: () => import('@/views/infoReleasePlatform/infoManagement/pushArticleManagement'),
        meta: { title: '推送文章管理', isMenu: true }
      },
      {
        path: 'involvementDetail/:id/:title',
        name: 'InvolvementDetail',
        component: () => import('@/views/infoReleasePlatform/infoManagement/pushArticleManagement/pages/involvementDetail'),
        meta: { title: '参与情况', isMenu: false, tagTitleSuffix: '参与情况' }
      },
      {
        path: 'commentManagement',
        name: 'CommentManagement',
        component: () => import('@/views/infoReleasePlatform/infoManagement/commentManagement'),
        meta: { title: '评论回收站', isMenu: true }
      }, {
        path: 'employeeFeedback',
        name: 'EmployeeFeedback',
        component: () => import('@/views/infoReleasePlatform/infoManagement/employeeFeedback'),
        meta: { title: '员工反馈', isMenu: true }
      }
    ]
  }, {
    path: 'basicSetting',
    name: 'BasicSetting',
    component: () => import('@/views/infoReleasePlatform/basicSetting'),
    meta: { title: '基础设置' },
    children: [
      {
        path: 'directoryManagement',
        name: 'DirectoryManagement',
        component: () => import('@/views/infoReleasePlatform/basicSetting/directoryManagement'),
        meta: { title: '目录管理', isMenu: true }
      }, {
        path: 'forumManagement',
        name: 'ForumManagement',
        component: () => import('@/views/infoReleasePlatform/basicSetting/forumManagement'),
        meta: { title: '论坛主题管理', isMenu: true }
      }, {
        path: 'forumApproverManagement',
        name: 'ForumApproverManagement',
        component: () => import('@/views/infoReleasePlatform/basicSetting/forumApproverManagement'),
        meta: { title: '论坛审核人管理', isMenu: true }
      }, {
        path: 'approverManagement',
        name: 'ApproverManagement',
        component: () => import('@/views/infoReleasePlatform/basicSetting/approverManagement'),
        meta: { title: '目录管理员配置', isMenu: true }
      },
      {
        path: 'groupManagement',
        name: 'GroupManagement',
        component: () => import('@/views/infoReleasePlatform/basicSetting/groupManagement'),
        meta: { title: '用户组管理', isMenu: true }
      },
      {
        path: 'publisherManagement',
        name: 'PublisherManagement',
        component: () => import('@/views/infoReleasePlatform/basicSetting/publisherManagement'),
        meta: { title: '发布人管理', isMenu: true }
      },
      {
        path: 'bannerSetting',
        name: 'BannerSetting',
        component: () => import('@/views/infoReleasePlatform/basicSetting/bannerSetting'),
        meta: { title: '轮播图配置', isMenu: true }
      },
      {
        path: 'sensitiveWordsManagement',
        name: 'SensitiveWordsManagement',
        component: () => import('@/views/infoReleasePlatform/basicSetting/sensitiveWordsManagement'),
        meta: { title: '敏感词管理', isMenu: true }
      }
    ]
  }
]

export default infoManagementRoutes