import { getToken } from '@/utils/auth'
import { parseTime } from '@/utils'

const requestDataHandle = (config) => {
  // 登录状态
  const token = getToken()
  if (!config?.headers) {
    config.headers = {}
  }
  if (!config?.data) {
    config.data = {}
  }
  config.headers.Authorization = token
  // 增加统一传参
  const defaultParams = {
    fromSystem: 'web_H5',
    toSystem: 'fh_kbms', // ---必填，通过网关转发系统时会校验
    reqId: new Date().getTime(), // 改成时间戳
    reqTime: parseTime(new Date().getTime()) // 时间格式 yyyy-mm-dd hh:mm:ss
  }
  // 用于上传文件
  if (config.headers['Content-Type'] === 'multipart/form-data') {
    const form = new FormData()
    const obj = { ...defaultParams, ...config.data }
    // 文件对象
    Object.keys(obj).forEach(k => {
      form.append(k, obj[k])
    }
    )
    return {
      newConfig: config,
      defaultParams: form,
      isFormData: true
    }
  }

  return {
    newConfig: config,
    defaultParams
  }
}
export default requestDataHandle